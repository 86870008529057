
import React from 'react';
import './App.scss';
import PagesHeader from './layouts/Header';
import Header from './auth/HeaderAuth';
import Sidebar from './layouts/Sidebar';
import Footer from './layouts/Footer';
import { SingleDevice } from './pages/dashboard/SingleDevice';
import { FleetDashboard } from './pages/dashboard/FleetDashboard';
import { DeviceEventsReport } from './pages/reports/DeviceEventsReport';
import OtaHistory from './pages/reports/ota-history/OtaHistory';
import OtaComms from './utils/ota-update/ota-live/otaComms';
import OtaControls from './utils/ota-update/ota-live/otaControls';
import Notifications from './pages/notifications/notifications';
import Downloads from './pages/downloads/downloads';
import { withAuthenticator} from '@aws-amplify/ui-react';
import { SignInHeader } from "./auth/SignInHeader";
import { SignInFooter } from "./auth/SignInFooter";
import awsconfigDev from './aws-exports/aws-exports-dev';
import awsconfigLabs from './aws-exports/aws-exports-labs';
import awsconfigQa from './aws-exports/aws-exports-qa';
import awsconfigStg from './aws-exports/aws-exports-staging';
import Amplify from 'aws-amplify';
import  {Suspense} from 'react';
import  DeviceMetaData from './pages/masters/DeviceMetaData';
import DeviceGroup from './pages/deviceGroup/deviceGroup';
import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';

var awsBackendConfig = "";
const NODE_ENV = process.env.NODE_ENV; 
const AMPLIFY_BUILD_ENV = process.env.REACT_APP_BUILD_ENV;
if(NODE_ENV == 'production'){
  if(AMPLIFY_BUILD_ENV == 'DEV'){
      awsBackendConfig = awsconfigDev;
  }else if(AMPLIFY_BUILD_ENV == 'LABS'){
      awsBackendConfig = awsconfigLabs;
  }else if(AMPLIFY_BUILD_ENV == 'QA'){
      awsBackendConfig = awsconfigQa;
  }else if(AMPLIFY_BUILD_ENV == 'STAGING'){
      awsBackendConfig = awsconfigStg;
  }else{
      awsBackendConfig = "";
  }
}else{
  awsBackendConfig = awsconfigDev;
}
console.log('awsBackendConfig:: ',awsBackendConfig)
Amplify.configure(awsBackendConfig);

export function App() {
  
  const location = useLocation();
  console.log('loc:: ',location);

  return (
    <div className="container-scroller font-styles mb-3" style={{backgroundColor:'#f0f1f6'}}>        
            <PagesHeader/>
          <div className="container-fluid page-body-wrapper">
            <Sidebar/>
          <div className="main-panel ">           
             
              <Suspense >    
                  <Routes location={location} key={location.pathname} >
                            <Route exact path='/' element={< FleetDashboard />}></Route>
                            <Route  path='/singleDevice' element={< SingleDevice />}></Route>                            
                            <Route  path='/deviceEventsReport' element={< DeviceEventsReport />}></Route>  
                            <Route  path='/otaComms' element={< OtaComms />}></Route>
                            <Route  path='/otaControls' element={< OtaControls />}></Route>
                            <Route  path='/otaHistory' element={< OtaHistory />}></Route>
                            <Route  path='/notifications' element={< Notifications />}></Route> 
                            <Route  path='/reportDownloads' element={< Downloads />}></Route> 
                            <Route  path='/masterDeviceMeta' element={< DeviceMetaData />}></Route>
                            <Route path='/deviceGroup' element={<DeviceGroup />}></Route> 
                  </Routes>
                </Suspense>
            <Footer/>
          </div>
          </div>
          
        </div>
      );

}     

export default withAuthenticator(App, {
  components: {
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  }
});