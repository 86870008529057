import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import setEnvironmentService from '../services/SetEnvironmentService';

let releaseNotesVersionData="";
let releaseNotesUrlData="";
let releaseNotesEnvLabelData = "";
let releaseNotesColorData = "";
class Sidebar extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      releaseNotesColor:"",
      releaseNotesEnvLabel:"",
      releaseNotesVersion:"",
      releaseNotesUrl:""

    };
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  async signOut() {
    try {
      console.log('signout called:: ');
        await Auth.signOut();
        //navigation('/');
        //userHasAuthenticated(false);
        //window.location.reload(true);
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/advanced-ui', state: 'advancedUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/maps', state: 'mapsMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/ota', state: 'otaMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
      {path:'/help', state: 'helpPagesMenuOpen'},
      {path:'/notifications', state: 'notificationsPagesMenuOpen'},
      {path:'/reportDownloads', state: 'downloadsMenuOpen'},
      {path:'/masterDeviceMeta', state: 'deviceMetaMenuOpen'},
      {path:'/release-notes', state: 'releaseNotesPagesMenuOpen'},
      {path:'/logout', state: 'logoutMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
 
  } 
  isPathActive(path) {
    //return this.props.location.pathname.startsWith(path);
  }
  async getEnvDetails(){
     releaseNotesColorData = (await setEnvironmentService.getRestUrlsByEnv()).releaseNotesLabelColor;
     releaseNotesEnvLabelData = (await setEnvironmentService.getRestUrlsByEnv()).releaseNotesLabel;
     releaseNotesVersionData = process.env.REACT_APP_RELEASE_NOTES_VERSION;
     releaseNotesUrlData = (await setEnvironmentService.getRestUrlsByEnv()).releaseNotesUrl;
    this.setState({releaseNotesColor:releaseNotesColorData});
    this.setState({releaseNotesEnvLabel:releaseNotesEnvLabelData});
    this.setState({releaseNotesVersion:releaseNotesVersionData});
    this.setState({releaseNotesUrl:releaseNotesUrlData});
    //console.log('releaseNotesColorData:: ',releaseNotesColorData)
    // console.log('releaseNotesEnvLabelData:: ',releaseNotesEnvLabelData)
    // console.log('releaseNotesVersionData:: ',releaseNotesVersionData)
    // console.log('releaseNotesUrlData:: ',releaseNotesUrlData)
  }
  componentDidMount() {
    this.getEnvDetails();
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      body.classList.add('sidebar-icon-only')
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }
  
  render () {    
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav" style={{position: "fixed"}}>
          
          <li className={ this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <span className="icon-bg"><i className="mdi mdi-desktop-mac menu-icon"></i></span>
              <span className="menu-title">Dashboard </span>            
            </div>
            <Collapse in={ this.state.basicUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"><Link className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' } to="/"> Fleet<label><span style={{ "color": "red" }}> .LIVE</span></label></Link></li>
                <li className="nav-item"><Link className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' }  to='/singleDevice'> Events<label><span style={{ "color": "red" }}> .LIVE</span></label></Link></li>
              </ul>
            </Collapse>
          </li>         
          <li className={ this.isPathActive('/charts') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('chartsMenuOpen') } data-toggle="collapse">
              <span className="icon-bg"><i className="mdi mdi-chart-bar menu-icon"></i></span>
              <span className="menu-title">Reports</span>
            </div>
            <Collapse in={ this.state.chartsMenuOpen }>
              <ul className="nav flex-column sub-menu">
              <li className="nav-item"><Link className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' }  to='/deviceEventsReport'>Device Events</Link></li>
              <li className="nav-item"><Link className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' }  to='/otaHistory'>OTA History</Link></li>
              <li className="nav-item"><Link className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' }  to='/reportDownloads'>Report Downloads</Link></li>

              </ul>
            </Collapse>
          </li>

          <li className={ this.isPathActive('/charts2') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.otaMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('otaMenuOpen') } data-toggle="collapse">
              <span className="icon-bg"><i className="mdi mdi-broadcast menu-icon"></i></span>
              <span className="menu-title">OTA Runtime</span>
            </div>
            <Collapse in={ this.state.otaMenuOpen }>
              <ul className="nav flex-column sub-menu">
              <li className="nav-item"><Link className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' }  to='/otaComms'>OTA Comms</Link></li>
              <li className="nav-item"><Link className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' }  to='/otaControls'>OTA Controls</Link></li>
              </ul>
            </Collapse>
          </li>

          <li className={ this.isPathActive('/notifications') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.notificationsPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('notificationsPagesMenuOpen') } data-toggle="collapse">
              <span className="icon-bg"><i className="mdi mdi-bell-outline menu-icon"></i></span>
              <span className="menu-title"><Link className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' }  to='/notifications'>Alerts & Notifications</Link></span>
            </div>           
          </li>

          <li className={ this.isPathActive('/masterDeviceMeta') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.deviceMetaMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('deviceMetaMenuOpen') } data-toggle="collapse">
              <span className="icon-bg"><i className="mdi mdi-border-color menu-icon"></i></span>
              <span className="menu-title">Masters</span>
            </div>
            <Collapse in={ this.state.deviceMetaMenuOpen }>
              <ul className="nav flex-column sub-menu">
              <li className="nav-item"><Link className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' }  to='/masterDeviceMeta'>Device Metadata</Link></li>
              <li className="nav-item"><Link className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' }  to='/deviceGroup'>Device Groups</Link></li>

              </ul>
            </Collapse>
          </li>


          <li className={ this.isPathActive('/release-notes') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.notificationsPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('notificationsPagesMenuOpen') } data-toggle="collapse">
              <span className="icon-bg"><span className={releaseNotesColorData}>{releaseNotesEnvLabelData}</span></span>
                <span className="menu-title">
                  <a className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' } rel="noopener noreferrer" href={releaseNotesUrlData} target="_blank">{releaseNotesVersionData} Release Notes</a>
                </span>
            </div>           
          </li>



          {/* <li className={ this.isPathActive('/release-notes') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.notificationsPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('notificationsPagesMenuOpen') } data-toggle="collapse">
              <span className="icon-bg"><span className="badge-orange">QA</span></span>
                <span className="menu-title">
                  <a className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' } rel="noopener noreferrer" href="https://chorus-webapp-dev-release-v1.s3.us-east-2.amazonaws.com/Webapp_QA_102022_Rel_v2_8_2.pdf" target="_blank">v2.8.2 Release Notes</a>
                </span>
                  <i className="menu-arrow"></i>
            </div>           
          </li> */}


          {/* <li className={ this.isPathActive('/release-notes') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.notificationsPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('notificationsPagesMenuOpen') } data-toggle="collapse">
              <span className="icon-bg"><span className="badge-blue">LABS</span></span>
              <b className="menu-title">
              <a className={ this.isPathActive('/') ? 'nav-link active' : 'nav-link' } rel="noopener noreferrer" href="https://chorus-webapp-labs-release-v1.s3.us-east-2.amazonaws.com/Webapp_Labs_102022_Rel_v2_8_2.pdf" target="_blank">v2.8.2 Release Notes</a>
              </b>
              <i className="menu-arrow"></i>
            </div>           
          </li> */}


          <li className={ this.isPathActive('/logoutMenuOpen') ? 'nav-item active' : 'nav-item' } href="!#" onClick={evt => evt.preventDefault()}>
            <div className={ this.state.helpPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('logoutMenuOpen') } data-toggle="collapse">
              <span className="icon-bg" onClick={this.signOut}><i className="mdi mdi-logout menu-icon"></i></span>
              <span className="menu-title" onClick={this.signOut}>Logout</span>
             
            </div>
          </li>
      
         
        </ul>
      </nav>
    );
  }

  

}

export default Sidebar;