import React from 'react';

var ota_url = "";

var modeChangeUrl = "";
var otaCommsUrl = "";
var otaControlsUrl = "";
var otaBinaryFromS3Url = "";
var generateEventReportUrl = ""
var releaseNotesUrl = "";
var releaseNotesLabelColor = "";
var releaseNotesLabel = "";
var generateExportMetadataUrl = "";
var manageDeviceGroupUrl = "";



class SetEnvironmentService extends React.Component{

    async getRestUrlsByEnv() {    
        const NODE_ENV = process.env.NODE_ENV; 
        const AMPLIFY_BUILD_ENV = process.env.REACT_APP_BUILD_ENV
        console.log('Amplify Env:: ',AMPLIFY_BUILD_ENV);    
        try{
            if(NODE_ENV == 'production'){
                console.log('Current Node Env is: ',NODE_ENV);
                if(AMPLIFY_BUILD_ENV == 'DEV'){
                    modeChangeUrl = process.env.REACT_APP_MODE_CHANGE_DEV;
                    otaCommsUrl = process.env.REACT_APP_OTA_COMMS_DEV;
                    otaControlsUrl = process.env.REACT_APP_OTA_CONTROLS_DEV;
                    otaBinaryFromS3Url = process.env.REACT_APP_GET_OTA_BINARY_FROM_S3;
                    generateEventReportUrl = process.env.REACT_APP_GENERATE_EVENT_REPORT_DEV;
                    releaseNotesUrl = process.env.REACT_APP_RELEASE_NOTES_DEV;
                    releaseNotesLabelColor = process.env.REACT_APP_RELEASE_NOTES_LABEL_COLOR_DEV;
                    releaseNotesLabel = process.env.REACT_APP_RELEASE_NOTES_LABEL_DEV;
                    generateExportMetadataUrl = process.env.REACT_APP_GENERATE_EXPORT_METADATA_DEV;
                    manageDeviceGroupUrl = process.env.REACT_APP_MANAGE_DEVICE_GROUP_DEV;

                }else if(AMPLIFY_BUILD_ENV == 'LABS'){
                    modeChangeUrl = process.env.REACT_APP_MODE_CHANGE_LABS;
                    otaCommsUrl = process.env.REACT_APP_OTA_COMMS_LABS;
                    otaControlsUrl = process.env.REACT_APP_OTA_CONTROLS_LABS;
                    otaBinaryFromS3Url = process.env.REACT_APP_GET_OTA_BINARY_FROM_S3;
                    generateEventReportUrl = process.env.REACT_APP_GENERATE_EVENT_REPORT_LABS;
                    releaseNotesUrl = process.env.REACT_APP_RELEASE_NOTES_LABS;
                    releaseNotesLabelColor = process.env.REACT_APP_RELEASE_NOTES_LABEL_COLOR_LABS;
                    releaseNotesLabel = process.env.REACT_APP_RELEASE_NOTES_LABEL_LABS;
                    generateExportMetadataUrl = process.env.REACT_APP_GENERATE_EXPORT_METADATA_LABS;
                    manageDeviceGroupUrl = process.env.REACT_APP_MANAGE_DEVICE_GROUP_DEV;
                }else if(AMPLIFY_BUILD_ENV == 'QA'){
                    modeChangeUrl = process.env.REACT_APP_MODE_CHANGE_QA;
                    otaCommsUrl = process.env.REACT_APP_OTA_COMMS_QA;
                    otaControlsUrl = process.env.REACT_APP_OTA_CONTROLS_QA;
                    otaBinaryFromS3Url = process.env.REACT_APP_GET_OTA_BINARY_FROM_S3;
                    generateEventReportUrl = process.env.REACT_APP_GENERATE_EVENT_REPORT_QA;
                    releaseNotesUrl = process.env.REACT_APP_RELEASE_NOTES_QA;
                    releaseNotesLabelColor = process.env.REACT_APP_RELEASE_NOTES_LABEL_COLOR_QA;
                    releaseNotesLabel = process.env.REACT_APP_RELEASE_NOTES_LABEL_QA;
                    generateExportMetadataUrl = process.env.REACT_APP_GENERATE_EXPORT_METADATA_QA;
                    manageDeviceGroupUrl = process.env.REACT_APP_MANAGE_DEVICE_GROUP_DEV;
                }else if(AMPLIFY_BUILD_ENV == 'STAGING'){
                    modeChangeUrl = process.env.REACT_APP_MODE_CHANGE_STG;
                    otaCommsUrl = process.env.REACT_APP_OTA_COMMS_STG;
                    otaControlsUrl = process.env.REACT_APP_OTA_CONTROLS_STG;
                    otaBinaryFromS3Url = process.env.REACT_APP_GET_OTA_BINARY_FROM_S3;
                    generateEventReportUrl = process.env.REACT_APP_GENERATE_EVENT_REPORT_STG;
                    releaseNotesUrl = process.env.REACT_APP_RELEASE_NOTES_STG;
                    releaseNotesLabelColor = process.env.REACT_APP_RELEASE_NOTES_LABEL_COLOR_STG;
                    releaseNotesLabel = process.env.REACT_APP_RELEASE_NOTES_LABEL_STG;
                    generateExportMetadataUrl = process.env.REACT_APP_GENERATE_EXPORT_METADATA_STG;
                    manageDeviceGroupUrl = process.env.REACT_APP_MANAGE_DEVICE_GROUP_DEV;
                }else{ //if no env fetched
                    modeChangeUrl = "";
                    otaCommsUrl = "";
                    otaControlsUrl = "";
                    otaBinaryFromS3Url = "";
                    generateEventReportUrl = "";
                    releaseNotesUrl = "";
                    releaseNotesLabelColor = "";
                    releaseNotesLabel = "";
                    manageDeviceGroupUrl = "";
                }
            }else{ // for development or local mode - DEV set bydefault
                console.log('Current Node Env is Local Env which is in: ',NODE_ENV);//replace var from above if aws_exports.js like LABS,QA,STG
                modeChangeUrl = process.env.REACT_APP_MODE_CHANGE_DEV;
                otaCommsUrl = process.env.REACT_APP_OTA_COMMS_DEV;
                otaControlsUrl = process.env.REACT_APP_OTA_CONTROLS_DEV;
                otaBinaryFromS3Url = process.env.REACT_APP_GET_OTA_BINARY_FROM_S3;
                generateEventReportUrl = process.env.REACT_APP_GENERATE_EVENT_REPORT_DEV;
                releaseNotesUrl = process.env.REACT_APP_RELEASE_NOTES_DEV;
                releaseNotesLabelColor = process.env.REACT_APP_RELEASE_NOTES_LABEL_COLOR_DEV;
                releaseNotesLabel = process.env.REACT_APP_RELEASE_NOTES_LABEL_DEV;
                generateExportMetadataUrl = process.env.REACT_APP_GENERATE_EXPORT_METADATA_DEV;
                manageDeviceGroupUrl = process.env.REACT_APP_MANAGE_DEVICE_GROUP_DEV;
            }
            var restUrlsByEnv = {'modeChangeUrl':modeChangeUrl,'otaCommsUrl':otaCommsUrl,'otaControlsUrl':otaControlsUrl,'otaBinaryFromS3Url':otaBinaryFromS3Url
            ,'generateEventReportUrl':generateEventReportUrl,'generateExportMetadataUrl':generateExportMetadataUrl,'releaseNotesUrl':releaseNotesUrl,'releaseNotesLabelColor':releaseNotesLabelColor
            ,'releaseNotesLabel':releaseNotesLabel,'manageDeviceGroupUrl':manageDeviceGroupUrl};
            return restUrlsByEnv;
        }catch(err) {
            console.log(err);           
        }
    }
}
export default new SetEnvironmentService();