import {queryReportExportHistoryByAccId} from '../graphql/queries';
import { API } from 'aws-amplify';
import authService from './AuthService';
var accountId = '';

const getAuthToken = () => 'custom-authorized';
const lambdaAuthToken = getAuthToken();  
var moment = require('moment'); // require


class ReportExportHistoryService{
  
   async getReportExportHistoryByAccId(){
    let exportHistoryDataList = [];
       try{
            accountId = (await authService.getCurrentUser()).accountId;
            const exportHistoryData = await API.graphql(({query:queryReportExportHistoryByAccId(accountId),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken}));
            let exportHistoryDataList  = exportHistoryData.data.ReportsExportHistoryByTenant.items;     
            for(let j=0;j<(exportHistoryDataList).length;j++){
                exportHistoryDataList[j].reportFromDateTime = moment(exportHistoryDataList[j].reportFromDateTime).local().format('YYYY-MM-DD HH:mm:ss')
                exportHistoryDataList[j].reportToDateTime = moment(exportHistoryDataList[j].reportToDateTime).local().format('YYYY-MM-DD HH:mm:ss')
                exportHistoryDataList[j].exportDateTime = moment(exportHistoryDataList[j].exportDateTime).local().format('YYYY-MM-DD HH:mm:ss')

            }

            return exportHistoryDataList;
       }catch(err){ console.log('Error Occured :: getReportExportHistoryByAccId() ', err); return null; };
   }
}

export default new ReportExportHistoryService();