import React  from "react";
import OTARuntimeService from '../../../services/OTARuntimeService';
import SharedService from '../../../services/SharedService';
import DeviceService from '../../../services/DeviceService';
import 'antd/dist/antd.css';
import { Button,Affix} from 'antd';
import FleetOtaUpdate from '../../components/modalComponent/fleetOtaUpdate';
import { DataTable } from '../../tables/DataTable';


export class OtaComms extends React.Component {
  constructor(props){
  super(props)
    this.state = {
        bottom:'bottomRight',
        rowData:[],
        jobStatusElement:'',
        visible:false,
        deviceData:[],
        refreshIntervalState:10000,
        filteredDeviceList:[],
        username:'',
        refreshState: true
    }
  }
  
  getDeviceData = () => {
    this.setState({deviceData:[]});
    DeviceService.getDevicesByAccId().then((resultByFilter) => {   
    let deviceNameMap = [];
      for(const dataObj of resultByFilter){
        if(deviceNameMap.find(x => x.udi === dataObj.udi)){
        }
        else{
          deviceNameMap.push({"deviceName":dataObj.udi,"deviceId":dataObj.id,"nickname":dataObj.metadata.name});
        }
      }
      this.setState({deviceData:deviceNameMap});
      
    });
  }

  getFilteredDeviceList=()=>{
    let deviceNameMap = [];
    OTARuntimeService.getOTALiveDataByAccId().then((res) => {
        for(let i=0;i<this.state.deviceData.length;i++){
            for(let j=0;j<res.length;j++){
                if(this.state.deviceData[i].deviceId === res[j].deviceId){
                  console.log('equal--',this.state.deviceData[i].deviceId ,'===,',res[j].deviceId)
                  console.log('status==',res[j].jobStatus)
                    if((res[j].jobStatus == 'CREATE_IN_PROGRESS') || (res[j].jobStatus == 'CREATE_PENDING')){
                      console.log('status==',res[j].jobStatus)
                        //deviceNameMap.push([]);
                    }else{
                      deviceNameMap.push({"deviceName":this.state.deviceData[i].deviceName,"deviceId":this.state.deviceData[i].deviceId,"nickname":this.state.deviceData[i].nickname});
                    }
                }
            }
           
        }
        this.setState({filteredDeviceList:deviceNameMap});
      }); 
  }

  handleOtaUpdateModal = (childData) => {
    this.setState({ visible: childData });
    this.clearIntervalOTALiveData = setInterval(() => { //starting refresh intervals on modal close
      this.getOTALiveDataByAccIdAndFirmwareType();
    }, 60000);
  }

  otaUpdateModalOpen = () =>{
    console.log('modal open ota');
    clearInterval(this.clearIntervalOTALiveData);  //stopping refresh intervals
    this.setState({visible:true});
    this.setState({firmwareType:'comms'});      
    this.getTargetFirmwareComms();    
    this.getFilteredDeviceList();
   }
   
   getTargetFirmwareComms = () =>{
    SharedService.fetchS3ObjectsOTABucket('comms').then((targetFirmwareListData) => {
        this.setState({targetFirmwareList:targetFirmwareListData})
    });
  }



  getOTALiveDataByAccIdAndFirmwareType(){   
   let result = [];
      OTARuntimeService.getOTALiveDataByAccIdFirmwareType('comms').then((res) => {
        //console.log('TelemetryApiData.getDevicesByAccId:: ',res);
        this.getFilteredDeviceList();
        for(let i=0;i<res.length;i++){
          if(res[i].device === null){
          }
          else{            
            result.push(res[i]);            
          }
        }
        this.setState({rowData:result});
        this.setState({ refreshState: false});
        
      }); 
    
    
  
  }
  getFilteredDeviceList=()=>{
    let deviceNameMap = [];
    var deviceId=""
    OTARuntimeService.getOTALiveDataByAccId().then((res) => {        
      for(var i=0;i<res.length;i++){
        if(res[i].jobStatus == "CREATE_IN_PROGRESS" || res[i].jobStatus == "CREATE_PENDING"){
           deviceId = res[i].deviceId;
        }
      }
       deviceNameMap = this.state.deviceData;        
        for(var i=0;i<deviceNameMap.length;i++){
          if(deviceNameMap[i].deviceId == deviceId){           
            var index = deviceNameMap.indexOf(deviceNameMap[i]);
            if (index > -1) { // only splice array when item is found
              deviceNameMap.splice(index, 1); // 2nd parameter means remove one item only
            }
          }
        }
        this.setState({filteredDeviceList:deviceNameMap});
      }); 
  }
  async getUsername() {
    try {
      await SharedService.getCurrentUser().then((userDataList) => {
        SharedService.getUserByEmailId(userDataList.attributes.email).then((userData) => {
          var userDataList = userData;
          if (userDataList == null || userDataList == [] || userDataList.length == 0) {
            this.setState({ username: [] });
          } else {
            this.setState({ username: userDataList[0].username })
          }
        });
      });

    } catch (err) {
      console.log('error on otaComms::getUsername:: ', err);
    }
  }

  

componentDidMount(){
  this.getUsername();
  setTimeout(() => {
  this.getOTALiveDataByAccIdAndFirmwareType();
  this.getDeviceData();
  },100)
  this.clearIntervalOTALiveData = setInterval(e => this.getOTALiveDataByAccIdAndFirmwareType(),this.state.refreshIntervalState);
}


componentWillUnmount() {
    clearInterval(this.clearIntervalOTALiveData);
}

render () {
  document.title='OTA Comms';
  return (
      <div className=" m-2">
        <Affix className="z-index" offsetTop={30}  >
          <div className="row p-1">
          <nav aria-label="breadcrumb justify-content-center align-self-center" >
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                OTA Comms <label style={{"color":"red" }}>.LIVE</label>
              </li>
                { this.state.username === 'christobel' || this.state.username === 'djorgji' ||  this.state.username === 'webtest' ||  this.state.username === 'cmills' || this.state.username === 'datta' ?                     
                <div className="col mb-2">               
                  <li className=" float-end border-0" >
                    {/* <PlusOutlined /> */}
                    <Button type="primary" onClick={this.otaUpdateModalOpen} >+ Add OTA</Button>
                  </li>             
                </div>
                : <div></div>}
            </ol>
            </nav>
          </div>
        </Affix>
        <div className="row">          
            <div className="justify-content-between align-items-center tab-transparent">           
                <div className="col-12 grid-margin">
                  <div className="card">                    
                    <div className="card-body">        
                      <div className="row">             
                        <DataTable refreshState={this.state.refreshState} title="otaComms" tableData = { this.state.rowData } state={true} />           
                          
                      </div>
                    </div>
                  </div>
            </div>
          </div>   
          <FleetOtaUpdate visible={this.state.visible} deviceListData={this.state.filteredDeviceList} targetFirmwareData={this.state.targetFirmwareList} firmwareType={'comms'} parentCallback = {this.handleOtaUpdateModal}/>   
        </div>
      </div>
    )
  }
}
export default OtaComms;

