
import React from "react";
import { Table, Tag, Progress, Space, Button, Input } from 'antd';
import "../../assets/styles/components/_data-table.scss";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import { HourglassOutlined, CloseOutlined } from '@ant-design/icons';

export class DataTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tableColumns: [],
      tableData: [],
      searchText: '',
      searchedColumn: '',
      loadingState: true,
      tableXWidth: 1200,
      tableYHeight: 1100,
      
    }
    this.searchInput = React.createRef();
  }

  searchInput = React.createRef();

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();   
    this.setState({ searchText: selectedKeys[0] });
    this.setState({ searchedColumn: dataIndex });
  };

  handleReset = (selectedKeys, confirm, dataIndex) => {
    confirm();  
    this.setState({ searchText: '' });
    this.setState({ searchedColumn: dataIndex });
  };

  dismissNotification = (notification) => {
    console.log("dismiss notification: ", notification);
    console.log(this.props);
    this.props.dismissNotification(notification);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8, }} >
        <Input ref={this.searchInput} placeholder={`Search ${dataIndex}`}  value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{  marginBottom: 8,  display: 'block', }} />
        <Space>
          <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />}   size="small"   style={{ width: 90}}> Search</Button>
          <Button   onClick={() => this.handleReset('', confirm, dataIndex)}  size="small" style={{width: 90 }}>Reset</Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{  color: filtered ? '#1890ff' : undefined,}}/>
    ),
    onFilter: (value, record) =>
      this.props.title === "notification" ? record.deviceName.toString().toLowerCase().includes(value.toLowerCase()) : record.device.metadata[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter highlightStyle={{backgroundColor: "#FFFF00",padding: 0 }} searchWords={[this.state.searchText]}  autoEscape
        textToHighlight={text ? text.toString() : ""} />) : (text)
    });

    eventLogColumns = [  
      {
        title: 'EventUUID',
        dataIndex: 'id',
        key: 'id',  
        // width: 110,
        align: 'left',
      },  
      {
        title: 'ClO2 (ppb)',
        dataIndex: ['clo2', 'value'],
        key: 'clo2',
        // width: 100,
        render: (text) => text.toFixed(2),
        sorter: (a, b) => (a.clo2.value) - (b.clo2.value),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Humidity (%)',
        dataIndex: ['humidity', 'value'],
        key: 'humidity',
        // width: 100,
        align: 'left',
        render: (text) => text.toFixed(2),
        sorter: (a, b) => (a.humidity.value) - (b.humidity.value),
        sortDirections: ['descend', 'ascend'], 
      },
      {
        title: 'Temp (°C)',
        dataIndex: ['temperature', 'value'],
        key: 'temperature',
        // width: 100,
        align: 'left',
        render: (text) => text.toFixed(2),
        sorter: (a, b) => (a.temperature.value) - (b.temperature.value),
        sortDirections: ['descend', 'ascend'],  
      },
      {
        title: 'Baro. P (mbar)',
        dataIndex:  ['barometricPressure', 'value'],
        key: 'barometricPressure',
        // width: 100,
        align: 'left',
        render: (text, record) => (text * 0.02953).toFixed(2),
        sorter: (a, b) => (a.barometricPressure.value) - (b.barometricPressure.value),
        sortDirections: ['descend', 'ascend'],  
      },
      {
        title: 'Cartridge (%)',
        dataIndex: 'cartridgeLevel',
        key: 'cartridgeLevel',
        // width: 100,
        align: 'left',
        renderCell: this.renderProgress,
        render: (text,record) =>{   
          return (
            <Progress
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
              }}
              percent={Math.floor(Math.random() * (98 - 96 + 1)) + 96}
            />
          );
        }
      },
      {
        title: 'Generated',
        dataIndex: 'generated',
        key: 'generated',
        // width: 100,
        render: (text) => text.toString() ,
        align: 'left',
      },
  
  
      {
        title: 'Shot Size (μL)',
        dataIndex: ['lastShotSize', 'value'],
        key: 'lastShotSize',
        // width: 100,
        align: 'left',
        render: (text) => text.toFixed(2),
        sorter: (a, b) => (a.lastShotSize.value) - (b.lastShotSize.value),
        sortDirections: ['descend', 'ascend'],  
      },
      
      {
        title: 'Timestamp',
        dataIndex: 'eventDate',
        key: 'eventDate',
        // width: 100,
        align: 'left',
        sorter: (a, b) => new Date(a.eventDate) - new Date(b.eventDate),
        sortDirections: ['descend', 'ascend'],  
      },
    ]

    mastersDeviceMetaColumns = [  
      {
        title: 'DeviceUUID',
        dataIndex: 'deviceId',
        key: 'id',  
        // width: 120,
        align: 'left',
      }, 
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        // width: 100,
        sortDirections: ['descend', 'ascend'],
        // ...this.getColumnSearchProps("name")
      },
      {
        title: 'Device Name',
        dataIndex: 'udi',
        key: 'udi',
        // width: 100,
        align: 'left',
        sortDirections: ['descend', 'ascend'], 
        // ...this.getColumnSearchProps("udi")
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        // width: 100,
        align: 'left',
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: 'Location Type',
      //   dataIndex: ['location', 'type'],
      //   key: 'name',
      //   width: 150,
      //   align: 'left',
      //   ...this.getColumnSearchProps("type")
      // },
      {
        title: 'Location',
        dataIndex: ['location'],
        key: 'name',
        // width: 150,
        align: 'left'
      },    
      {
        title: 'Created Timestamp',
        dataIndex: 'createdAt',
        key: 'createdAt',
        // width: 100,
        align: 'left',
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        sortDirections: ['descend', 'ascend'],  
      },
      {
        title: 'Updated Timestamp',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        // width: 100,
        align: 'left',
        sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
        sortDirections: ['descend', 'ascend'],  
      },
    ]

    commsColumns = [
      {
        title: 'Name', dataIndex: ['device', 'metadata', 'name'], key: 'name', 
        width: 110, 
        align: 'left',
        // ...this.getColumnSearchProps("name")
      },  
      {
        title: 'Job Name', dataIndex: 'jobName', key: 'jobName', 
        // width: 80,
      },
      {
        title: 'Job Status', dataIndex: 'jobStatus', key: 'jobStatus', 
        // width: 150, 
        align: 'left',
      },
      {
        title: 'Requested By', dataIndex: 'requestedBy', key: 'requestedBy', 
        // width: 150, 
        align: 'left',
      },
      {
        title: 'Executed By', dataIndex: 'executedBy', key: 'executedBy', 
        // width: 150,
      },
      {
        title: 'Comms Version', dataIndex: ['device', 'commsVersion'], key: 'commsVersion', 
        // width: 150,
      },
      {
        title: 'Target Firmware', dataIndex: 'targetFirmware', key: 'targetFirmware', 
        // width: 200,
      },
      {
        title: 'Updated Timestamp', dataIndex: 'updatedAt', key: 'updatedAt', 
        // width: 200,
        sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt), sortDirections: ['descend', 'ascend'], 
      },
      {
        title: 'Created Timestamp', dataIndex: 'createdAt', key: 'createdAt', 
        // width: 200,
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt), sortDirections: ['descend', 'ascend'],  
      },
    ];

    controlColumns = [
      {
        title: 'Name', dataIndex: ['device', 'metadata', 'name'], key: 'name', 
        width: 110, 
        align: 'left',
        // ...this.getColumnSearchProps("name")
      },  
      {
        title: 'Job Name', dataIndex: 'jobName', key: 'jobName', 
        // width: 300,
      },
      {
        title: 'Job Status', dataIndex: 'jobStatus', key: 'jobStatus', 
        // width: 150, 
        align: 'left',
      },
      {
        title: 'Requested By', dataIndex: 'requestedBy', key: 'requestedBy', 
        // width: 150, 
        align: 'left',
      },
      {
        title: 'Executed By', dataIndex: 'executedBy', key: 'executedBy', 
        // width: 150,
      },
      {
        title: 'Target Firmware', dataIndex: 'targetFirmware',  key: 'targetFirmware', 
        // width: 200,
      },
      {
        title: 'Updated Timestamp', dataIndex: 'updatedAt', key: 'updatedAt', 
        // width: 200,
        sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt), sortDirections: ['descend', 'ascend'],  
      },
      {
        title: 'Created Timestamp', dataIndex: 'createdAt', key: 'createdAt', 
        // width: 200,
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt), sortDirections: ['descend', 'ascend'], 
      },
    ];

    otaHistoryColumns = [
      {
        title: 'Name', dataIndex: ['device', 'metadata', 'name'], key: 'name', width: 110, align: 'left',
        // ...this.getColumnSearchProps("name")
      },  
      {
        title: 'Job Name', dataIndex: 'jobName', key: 'jobName', 
        // width: 300,
      },
      {
        title: 'Type', dataIndex: 'firmwareType', key: 'firmwareType', 
        // width: 100, 
        align: 'left', 
      },
      {
        title: 'Job Status', dataIndex: 'jobStatus', key: 'jobStatus', 
        // width: 150, 
        align: 'left',
      },
      {
        title: 'Requested By', dataIndex: 'requestedBy', key: 'requestedBy', 
        // width: 150, 
        align: 'left',
      },
      {
        title: 'Executed By', dataIndex: 'executedBy', key: 'executedBy', 
        // width: 150,
      },
      {
        title: 'Comms version', dataIndex: ['device', 'commsVersion'], key: 'executedBy', 
        // width: 150,
      },
      {
        title: 'Controller version', dataIndex: ['device', 'controllerVersion'], key: 'executedBy', 
        // width: 150,
      },
      {
        title: 'Target Firmware', dataIndex: 'targetFirmware', key: 'targetFirmware', 
        // width: 200,
      },
      {
        title: 'Updated Timestamp', dataIndex: 'updatedAt', key: 'updatedAt', 
        // width: 200,
        sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
        sortDirections: ['descend', 'ascend'], 
      },
      {
        title: 'Created Timestamp', dataIndex: 'createdAt', key: 'createdAt', 
        // width: 200,
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        sortDirections: ['descend', 'ascend'],  
      },
    ]; 

    notificationColumns = [     
      {
        title: 'Name',
        dataIndex: 'deviceName',
        key: 'name',  
        // width: 110,
        align: 'left',
        // ...this.getColumnSearchProps('deviceName')
      },  
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        // width: 300,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        // width: 100,
        align: 'left',
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: 100,
        align: 'left',
      },
      {
        title: 'Timestamp',
        dataIndex: 'createdAt',
        key: 'createdAt',
        // width: 100,
        align: 'left',
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      },
      {
        title: '-',
        dataIndex: '',
        key: 'dismiss',
        width: 100,
        align: 'center',
        render: (text, record) => {
          return (<i onClick={(e) => {e?.stopPropagation(); this.dismissNotification(record)}} className="mdi mdi-18px mdi-minus-circle-outline close-alert"></i>)  
        }
      },
    ];

    reportDownloadsColumns = [
      {
        title: 'Download Link',
        dataIndex: 'destinationUrl',
        key: 'destinationUrl', 
        //  width: 600,
        render: (text, record) => {
          if(record.downloadStatus === 'FAILED'){
            return (<div><i><CloseOutlined style={{color: 'red'}}/> Sorry! Report failed to generate!</i></div>)
          }else{
            if(record.destinationUrl === ' '){
              return (<div><i><HourglassOutlined spin style={{color: '#389e0d'}}/> Your Report is being prepared</i></div>)
            }else{
              if(record.filter.reportType === 'Device Metadata'){
                return(<a href={record.destinationUrl}>DeviceMetaData_{record.exportDateTime}.csv</a>)
              }else{
                return(<a href={record.destinationUrl}>EventReport_{record.filter.reportToDateTime}.csv</a>)
              }
            }
          }
        }
      },
      {
        title: 'Status',
        key: 'downloadStatus',
        dataIndex: 'downloadStatus',
        // width:150,
        render: (downloadStatus) => {
          let color = ''
          if (downloadStatus === 'FAILED') {
            color = 'volcano';
          }
          if (downloadStatus === 'COMPLETED') {
            color = 'green';
          }
          if (downloadStatus === 'IN-PROGRESS') {
            color = 'geekblue';
          }
          return (
            <Tag color={color} key={downloadStatus}>
              {downloadStatus.toUpperCase()}
            </Tag>
          );
        }
      },
      {
        title: 'Report Type',
        dataIndex: 'reportType',
        key: 'reportType',
        render: (text, record) => {  
          
            return(<>{record.reportType}</>)
          
        }
      },
      {
        title: 'Filters Applied',
        dataIndex: 'filter',
        key: 'filter',
        render: (text, record) => {  
          if(record.reportType === 'Device Metadata'){
            return(<><Tag color="geekblue">{record.filter.devices}</Tag></>)
          }  
          else{
            return(<>{record.filter.devices === ""?<Tag color="geekblue">All Devices</Tag>: <Tag color="geekblue">{record.deviceName}</Tag>}<Tag color="orange"><i>From: </i>{record.filter.reportFromDateTime}</Tag><Tag color="gold"><i>To: </i>{record.filter.reportToDateTime}</Tag></>)
          }    
        }
      },
      {
        title: 'Created Timestamp',
        dataIndex: 'exportDateTime',
        key: 'exportDateTime',
        sorter: (a, b) => new Date(a.exportDateTime) - new Date(b.exportDateTime),
        sortDirections: ['descend', 'ascend'], 
      },
    ];


    deviceGroupColumns = [
      {
        title: 'Group Name',
        dataIndex: 'groupName',
        key: 'groupName', 
        
      },
      {
        title: 'Things',
        dataIndex: 'things',
        key: 'things', 
        render: (text, record) => {
          if(record.things.length > 1){
            return (              
            <div>  {record.things.map((make) => (
              <Tag color="purple" >{make}</Tag>
            ))}</div>)
          }else{
            return (
              <Tag color="purple" >{record.things}</Tag>
             )

           
          }
        }
      },
    ];

  componentDidMount(){
    if (this.props.title === "eventLog"){
      if(window.screen.width > 720){
        for (const column of this.eventLogColumns){
          if (column.title === "EventUUID"){
            column.width = 300;
          }
          if (column.title === "Timestamp"){
            column.width = 200;
          }
          if (column.title === "Cartridge Level (%)"){
            column.width = 150;
          } 
        }
      }
      this.setState({ tableColumns: this.eventLogColumns })
      this.setState({ tableData: this.props.tableData })
    }
    else if (this.props.title === "otaComms") {
      this.setState({ tableColumns: this.commsColumns })
      this.setState({ tableData: this.props.tableData })
    }
    else if (this.props.title === "otaControl"){
      this.setState({ tableColumns: this.controlColumns })
      this.setState({ tableData: this.props.tableData })
    }
    else if (this.props.title === "otaHistory"){
      this.setState({ tableColumns: this.otaHistoryColumns })
      this.setState({ tableData: this.props.tableData })
    }
    else if (this.props.title === "notification"){
      this.setState({ tableColumns: this.notificationColumns })
      this.setState({ tableData: this.props.tableData })
    }
    else if (this.props.title === "deviceGroup"){
      this.setState({ tableColumns: this.deviceGroupColumns })
      this.setState({ tableData: this.props.tableData })
    }
    else if (this.props.title === "mastersDeviceMeta"){
      if(window.screen.width > 720){
        for (const column of this.mastersDeviceMetaColumns){
          if (column.title === "DeviceUUID"){
            column.width = 300;
          }
          if (column.title === "Created Timestamp"){
            column.width = 200;
          }
          if (column.title === "Updated Timestamp"){
            column.width = 200;
          }

        }
      }
      this.setState({ tableColumns: this.mastersDeviceMetaColumns })
      this.setState({ tableData: this.props.tableData })
    }
    else{
      this.setState({ tableColumns: [] })
      this.setState({ tableData: [] })
    } 
  }

  adjustTableColumnHeight = () => {
    if (window.screen.width <= 720){
      let thElement = document.querySelectorAll('.ant-table-thead tr th');
      let tdElement = document.querySelectorAll('.ant-table-tbody tr td');
      const tableHeadersSize = thElement.length;
      for (let i = 0; i < tableHeadersSize; i++){
        for (let j = i ; j < tdElement.length; j = j + tableHeadersSize){
          const thHeight = thElement[i].clientHeight;
          const tdHeight = tdElement[j].clientHeight;
          if (thHeight > tdHeight){
            // set td height as th height
            tdElement[j].setAttribute("style", "height:" + (thHeight) + "px");
          }
          else{
            // set th height as td height
            thElement[i].setAttribute("style", "height:" + (tdHeight) + "px");
          }
        } 
      }
    }
  }

  componentDidUpdate(prevProps){

    if (prevProps.tableData !== this.props.tableData || prevProps.title != this.props.title){
      if (this.props.title === "reportDownloads"){
        if(window.screen.width >= 720){
          for (const column of this.reportDownloadsColumns){
            if (column.title === "Download Link"){
              column.width = 250;
            }  
            if (column.title === "Filters Applied"){
              column.width = 420;
            }  
            if (column.title === "Created Timestamp"){
              column.width = 200;
            }  
            if (column.title === "Report Type"){
              column.width = 200;
            }  
            if (column.title === "Status"){
              column.width = 150;
            }
            if (column.title === "Username"){
              column.width = 150;
            }
          }
        }
        if (this.props.userRole === "ADMIN" || this.props.userRole === "ADMIN_OTA"){
          var usernameColumn = { title: 'Username', dataIndex: 'exportedBy', key: 'exportedBy', width: 160 };
          let adminColumns = this.reportDownloadsColumns;
          adminColumns.splice(2, 0, usernameColumn);
          this.setState({ tableColumns: adminColumns })
          this.setState({ tableData: this.props.tableData })
        } else{
          console.log("user role: update: ", this.props.userRole);
          this.setState({ tableColumns:  this.reportDownloadsColumns })
          this.setState({ tableData: this.props.tableData })
        }
      } 
      this.adjustTableColumnHeight();
    }
    if (this.props.title !== prevProps){
      this.adjustTableColumnHeight();
    }
  }
  
  handlePageChange = (pagination) => {
    console.log("page change");
    setTimeout(() => {
      this.adjustTableColumnHeight();
    }, 500); 
  }
   
  render() {
    return (
      <div className="mb-4">
        <Table className={this.props.title + "-table"} size={'small'} 
          columns={this.state.tableColumns} 
          pagination={{ showSizeChanger: false,
          pageSize: 10, 
          position: ['bottomRight'], 
          onChange: (pagination, filters, sorter, currentPageData) => this.handlePageChange(pagination) }}
          scroll={{ y: 1100, x: 1200 }} 
          dataSource={this.props.tableData} 
          loading= {this.props.refreshState }
          />
      </div> 
    )
  }
}   
export default DataTable;