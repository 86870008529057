import { Auth , Signer} from 'aws-amplify';
import { API } from 'aws-amplify';
import { listUserByEmailId} from '../graphql/queries';
import React from 'react';
import axios from 'axios';
import authService from './AuthService';
import setEnvironmentService from './SetEnvironmentService';
const getAuthToken = () => 'custom-authorized';
const lambdaAuthToken = getAuthToken();  
 var accountId= '';

 var userEmailId = '';
 var username = '';
class SharedService extends React.Component{

    async getCurrentUser() {
       // console.log("getCurrentUser()");
        try{
            const userProfileData = await Auth.currentAuthenticatedUser();
            //console.log("User group of logged-in user: ",userProfileData.signInUserSession.accessToken.payload['cognito:groups']);
           //console.log("Username of logged-in user: ",userProfileData.username);
            accountId = userProfileData.signInUserSession.accessToken.payload['cognito:groups'][0];
            userEmailId = userProfileData.attributes.email;
            username =userProfileData.username;
            //accountId = userProfileData.signInUserSession.accessToken.payload['cognito:groups']
            //this.setState({tenantNameState:userProfileData.signInUserSession.accessToken.payload['cognito:groups']});
            //console.log("set state tenantNameState:",accountId);
            //console.log("set state userEmailId:",userEmailId);
            return userProfileData;
        }catch(err) {
            console.log(err);
            //console.log("inside getCurrentUser catch");
        }

    }
     
 // sign a request using Amplify Auth and Signer
 async signRequest(url, method, service, region, data) {
    try {
    const essentialCredentials = Auth.essentialCredentials(await Auth.currentCredentials());

    const params = { 
      method, 
      url, 
      headers: { 
      'Content-Type': 'application/json'
      },
      data: JSON.stringify(data) 
    };

    const credentials = {
        secret_key: essentialCredentials.secretAccessKey,
        access_key: essentialCredentials.accessKeyId,
        session_token: essentialCredentials.sessionToken,
    };
    // set your region and service here
    const serviceInfo = {region, service};
    // Signer.sign takes care of all other steps of Signature V4
    return Signer.sign(params, credentials, serviceInfo);

    }catch(err){ console.log('Error Occured signRequest:: ',err); return null; }
}


    async sendCommandForChangeMode(deviceId,Udi,mode) {
        console.log('sendCommandForChangeMode :: deviceId:: ',deviceId,'-Udi:: ',Udi,"-mode:: ",mode);
        try {
            const inputCommandForDevices = {
            "deviceUUID": deviceId,
            "deviceName": Udi,
            "data": {
                "deviceTargetMode": mode
            }
            }
            let changeModeUrl = (await setEnvironmentService.getRestUrlsByEnv()).modeChangeUrl;
            let region_name = "us-east-2"
            const url = changeModeUrl+deviceId+'/command';

            const signedRequest = await this.signRequest(url, "POST", "execute-api", region_name, inputCommandForDevices);
            const response = await fetch(signedRequest.url, {
            method: "POST",
            mode: 'cors',
            cache: 'no-cache',
            headers: signedRequest.headers,
            referrer: 'client',
            body: signedRequest.data
            });

            if (response.ok) {
            return response.json();
            } else {
            throw new Error("Failed Request");
            }
        }catch(err){ console.log('Error Occured sendCommandForChangeMode:: ',err); return null; }
    }



        
    async fetchS3ObjectsOTABucket(firmwareType){
        try{
            var s3OTAUrl = (await setEnvironmentService.getRestUrlsByEnv()).otaBinaryFromS3Url;
            var s3ObjectList = [];
            
            const s3ObjectData = await axios
            .post(s3OTAUrl,null,{ params: {
                "s3BucketName":firmwareType,
            }})
            .then(data =>{
                s3ObjectList = data.data;
                return s3ObjectList;
            }) 
            .catch(error => {
                console.log(error);
                return null;
            });
            return s3ObjectData;      
        }catch(err){
            console.log('Error Occured fetchS3ObjectsOTABucket:: ',err); return null; }
    }
    async createOTAJobForComms(jobName,targetSelection,thingName,targetFirmware,deviceId,requestedBy){
        console.log('comms--','jobName--',jobName,'targetSelection--',targetSelection,'thingName--',thingName,'targetFirmware--',
        targetFirmware,'deviceId--',deviceId,'requestedBy--',requestedBy,username)
        try{
            accountId = (await authService.getCurrentUser()).accountId;
            var otaCommsUrl = (await setEnvironmentService.getRestUrlsByEnv()).otaCommsUrl;
            var otaJobCreationResponse = [];
            const otaJObResponse = await axios
            .post(otaCommsUrl,null,{ params: {                
                "jobName":jobName,
                "targetSelection":targetSelection,
                "thingName":thingName,
                "targetFirmware":targetFirmware,
                "accountID":accountId,
                "deviceId":deviceId,
                "requestedBy":requestedBy,
                "executedBy":username,
              }})
            .then(data =>{
                otaJobCreationResponse = data.data;
                return otaJobCreationResponse;
            }) 
            .catch(error => {
                console.log(error);
                return null;
            });
            return otaJObResponse;    
           
        }catch(err){
            console.log('Error Occured fetchS3ObjectsOTABucket:: ',err); return null; }
    }

    async createOTAJobControls(jobName,targetSelection,thingName,targetFirmware,deviceId,requestedBy){
        console.log('controls--','jobName--',jobName,'targetSelection--',targetSelection,'thingName--',thingName,'targetFirmware--',
        targetFirmware,'deviceId--',deviceId,'requestedBy--',requestedBy,username)
        try{
            accountId = (await authService.getCurrentUser()).accountId;
            var otaControlsUrl = (await setEnvironmentService.getRestUrlsByEnv()).otaControlsUrl;
            var otaJobCreationResponse = [];
            const otaJObResponse = await axios
            .post(otaControlsUrl,null,{ params: {
                "jobName":jobName,
                "targetSelection":targetSelection,
                "thingName":thingName,
                "targetFirmware":targetFirmware,
                "accountID":accountId,
                "deviceId":deviceId,
                "requestedBy":requestedBy,
                "executedBy":username
              }})
            .then(data =>{
                otaJobCreationResponse = data.data;
                return otaJobCreationResponse;
            }) 
            .catch(error => {
                console.log(error);
                return null;
            });
            return otaJObResponse;             
                
        }catch(err){
            console.log('Error Occured fetchS3ObjectsOTABucket:: ',err); return null; }
    }


     //get list of unique locations
     async getUserByEmailId(userEmail){
        try {
            console.log('userEmailId:: ',userEmailId)
            //var userEmail = 'vishal.jaiswal@jashds.com'
            const userData = await API.graphql({query:listUserByEmailId(userEmail),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken})
            const userList = userData.data.listUsers.items;
            return userList;
          }
        catch (err) { 
            console.log('Error Occured getUserByEmailId:: ',err); return null; 
        }
    }

    async generateEventsReport(fromDateTime,toDateTime,reportType,offsetLocalTimeZone){
      
        try{
            var generateEventReportUrl = (await setEnvironmentService.getRestUrlsByEnv()).generateEventReportUrl;
            accountId = (await authService.getCurrentUser()).accountId;
            console.log('fromDateTime--',fromDateTime,'toDateTime--',toDateTime,'exportedBy--',username,'accountID--',accountId);
            var reportGenerationResponse = [];
            const downloadResponse = await axios
            .post(generateEventReportUrl,null,{ params: {                
                "reportFromDateTime":fromDateTime,
                "reportToDateTime":toDateTime,
                "exportedBy":username,               
                "accountID":accountId,
                "reportType":reportType,
                "offset":offsetLocalTimeZone           
              }})
            .then(data =>{
                reportGenerationResponse = data.data;
                return reportGenerationResponse;
            }) 
            .catch(error => {
                console.log(error);
                return null;
            });
            return downloadResponse;    
           
        }catch(err){
            console.log('Error Occured generateEventsReport:: ',err); return null; }
    }

    async generateEventsReportByDeviceId(deviceId,fromDateTime,toDateTime,reportType,offsetLocalTimeZone){
      
        try{
            var generateEventReportUrl = (await setEnvironmentService.getRestUrlsByEnv()).generateEventReportUrl;
            accountId = (await authService.getCurrentUser()).accountId;
            console.log('fromDateTime--',fromDateTime,'toDateTime--',toDateTime,'exportedBy--',username,'accountID--',accountId,'deviceId--',deviceId);
            var reportGenerationResponse = [];
            const downloadResponse = await axios
            .post(generateEventReportUrl,null,{ params: {                
                "reportFromDateTime":fromDateTime,
                "reportToDateTime":toDateTime,
                "exportedBy":username,               
                "accountID":accountId,
                "reportType":reportType,
                "offset":offsetLocalTimeZone,
                "deviceId":deviceId        
              }})
            .then(data =>{
                reportGenerationResponse = data.data;
                return reportGenerationResponse;
            }) 
            .catch(error => {
                console.log(error);
                return null;
            });
            return downloadResponse;    
           
        }catch(err){
            console.log('Error Occured generateEventsReport:: ',err); return null; }
    }

    async generateMetaDataExport(reportType,offsetLocalTimeZone){
      
        try{
            var generateMetaDataExportUrl = (await setEnvironmentService.getRestUrlsByEnv()).generateExportMetadataUrl;
            accountId = (await authService.getCurrentUser()).accountId;
            console.log('exportedBy--',username,'accountID--',accountId);
            var metadataGenerationResponse = [];
            const downloadResponse = await axios
            .post(generateMetaDataExportUrl,null,{ params: {                
                "exportedBy":username,               
                "accountID":accountId,
                "reportType":reportType,
                "offset":offsetLocalTimeZone
              }})
            .then(data =>{
                metadataGenerationResponse = data.data;
                return metadataGenerationResponse;
            }) 
            .catch(error => {
                console.log(error);
                return null;
            });
            return downloadResponse;    
           
        }catch(err){
            console.log('Error Occured generateMetaDataExport:: ',err); return null; }
    }


async createDeviceGroup(groupName,deviceList,actionType){
    
    try{
        var manageDeviceGroupUrl = (await setEnvironmentService.getRestUrlsByEnv()).manageDeviceGroupUrl;
        var tempList = [];
        tempList.push('prov_airwolf30C6F7DDE494')
        var deviceGroupResponseData = [];
        var payloadBody = {
            thingGroupName:groupName,
            thingName:deviceList,
            actionType:actionType,
        }
        const deviceGroupResponse = await axios
        .post(manageDeviceGroupUrl, JSON.stringify(payloadBody))
        .then(data =>{
            deviceGroupResponseData = data.data;
            return deviceGroupResponseData;
        }) 
        .catch(error => {
            console.log(error);
            return null;
        });
        return deviceGroupResponse;    
       
    }catch(err){
        console.log('Error Occured createDeviceGroup Service:: ',err); return null; }
}
}
export default new SharedService();