import React from 'react';
import FleetDashboard from './FleetDashboard';
import DeviceTelemetryService from "../../services/DeviceTelemetryService";
import './SingleDeviceStyle.scss';
import DataTable from "../../utils/tables/DataTable";
import DeviceMeta from '../../utils/components/modalComponent/deviceMeta';
import { Menu, Dropdown } from 'antd';
import { DownOutlined, SyncOutlined } from '@ant-design/icons';
import { DatePicker, Select, Space, TimePicker } from 'antd';
import DeviceService from '../../services/DeviceService';
import LocationService from '../../services/LocationService';
import DeviceStateService from '../../services/DeviceStateService';
import SharedService from '../../services/SharedService';
import TimeseriesChartComponent from '../../utils/components/chartComponent/timeseriesChartComponent';
import { Button, Affix } from 'antd';
import 'antd/dist/antd.css';
import { Dropdown as DropdownReact } from 'react-bootstrap';
import './SingleDeviceStyle.scss';
import SingleDeviceOtaUpdate from '../../utils/components/modalComponent/singleDeviceOtaUpdate';
import StateChartComponent from '../../utils/components/chartComponent/stateChartComponent';
import ThresholdComponent from '../../utils/components/chartComponent/thresholdComponent';
import LevelComponent from '../../utils/components/chartComponent/levelComponent';
import { BreadcrumbComponent } from '../../utils/components/breadcrumbComponent';
import TelemetrySharedFunction from '../../utils/functions/telemetrySharedFunction';
const { RangePicker } = DatePicker;
const { Option } = Select;
var type = 'time';


var moment = require('moment'); // require
var deviceIdRouteValue = null;
export class SingleDevice extends React.Component {
  constructor(props) {

    super(props)
    this.state = {
      clo2DonutValue: [],
      humidityDonutValue: [],
      temperatureDonutValue: [],
      barometricDonutValue: [],
      deviceId: '',
      deviceMapState: [],
      latestDeviceNameState: [],
      latestDeviceIdState: [],
      datagridTelemetryMapState: [],
      generationEventMapState: [],
      timeSeriesChartClo2Data: {},
      timeSeriesChartGenerationData: {},
      selectedTimeRange: [],
      defaultTimeFilterKeyState: "Now",
      defaultTimeFilterValueState: 2,
      selectedTimeFilterValueState: 0,
      selectedDeviceIdValueState: 0,
      clo2Shadow: [],
      clo2Solid: [],
      tempShadow: [],
      tempSolid: [],
      rhShadow: [],
      rhSolid: [],
      baroShadow: [],
      baroSolid: [],
      nickname: [],
      mode: [],
      modesList: [],
      location: [],
      open: false,
      visible: false,
      deviceMetaData: {},
      cartridgeLevelValue: 0,
      tableDataRefreshState: [],
      timeSeriesRefreshState: [],
      thresholdRefreshState:[],
      latestUdi: "",
      locationDataState: [],
      modeState: '',
      modeIcon: '',
      modeColor: '',
      refreshIntervalState: 30000,
      selectedRefreshValue: "30s",
      timeFilter: 'true',
      fromDateTime: moment().subtract(2, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z",
      toDateTime: moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z",
      typeOfDateFilter: 'time',
      targetFirmwareCommsList: [],
      targetFirmwareControlsList: [],
      onClickDateTimeFilter: false,
      userRole: '',
      stateChartSeries: [],
      stateChartRefreshState: [],
      firmwareType: '',
      username: '',
      target: '',
      tabTitle: '',
      top: 100,
      tsLabel: [],
      tsClo2Data: [],
      tsShotSizeData: [],
      cartidgeLevel:0,
      commsVersion: null,
      controllerVersion: null,
      tsTimeFilterMins:2,
      tsHumidityData:[],
      avgClO2Value: 0,
      openMobileFilterBar: false,
      avgClO2RangeState:true,
      avgClO2RangeValue:'[8h]',
      minClO2Value:0,
      maxClO2Value:0,
      selectedDatetime: [],
    }
    this.instanceOfA = new FleetDashboard();
    deviceIdRouteValue = this.instanceOfA.data.deviceId;
    //console.log('dashboaordState',deviceIdRouteValue);    
  }
  setRoutingValues = (deviceIdRouteValue) => {
    // clearInterval(this.clearIntervalTelemetryData);
    this.setState({ selectedTimeFilterValueState: 2 });
    //console.log('selected time val:',this.state.selectedTimeFilterValueState);
    this.getDeviceTelemetryByFilter(deviceIdRouteValue);
    this.getMasterLocationData();
    this.createClo2TimeSeriesMapData('y', deviceIdRouteValue, 2);
    this.createModeTimeSeriesArrayData('y', deviceIdRouteValue, 2);
    DeviceService.getDevicesByAccId().then((res) => {
      let deviceNameMap = [];
      let hierarchy = [];
      let hierarchyList = [];
      let hierarchyResult = '';
      for (const dataObj of res) {
        if (deviceNameMap.find(x => x.udi === dataObj.udi)) {
        }
        else {
          const deviceData = { 
            "deviceName": dataObj.udi,
            "deviceId": dataObj.id,
            "nickname": dataObj.metadata.name
          }
          deviceNameMap.push(deviceData);
        }
      }
      this.setState({ deviceMapState: deviceNameMap });
      this.setState({ latestDeviceIdState: deviceIdRouteValue });
      this.setState({ selectedDeviceIdValueState: deviceIdRouteValue });
      this.setState({ latestDeviceNameState: this.state.deviceTelemetryByFilterList[0].metadata.name });
      this.setState({ tabTitle: this.state.deviceTelemetryByFilterList[0].metadata.name });
      if (this.state.deviceTelemetryByFilterList[0].metadata.location != null) {
        if (this.state.deviceTelemetryByFilterList[0].metadata.parentHierarchy != "") {
          hierarchy.push(this.state.deviceTelemetryByFilterList[0].metadata.parentHierarchy.split('#'));
          for (let i = 1; i < hierarchy[0].length; i++) {
            for (let j = 0; j < this.state.locationDataState.length; j++) {
              if (hierarchy[0][i] === this.state.locationDataState[j].id) {
                hierarchyList.push({ "value": this.state.locationDataState[j].value });
                hierarchyResult = hierarchyResult.concat(this.state.locationDataState[j].value + '/')
              }
            }
          }
        }
        else {
          hierarchyResult = 'None/';
        }
        hierarchyResult = hierarchyResult.slice(0, -1);
        this.setState({ location: hierarchyResult });
      }
      else {
        this.setState({ location: 'None' });
      }

    })

  }

  onChange = (value, dateString) => {
    this.setState({ selectedDatetime: value});
    this.setState({ onClickDateTimeFilter: true });
    this.setState({ timeSeriesRefreshState: true });
    this.setState({ stateChartRefreshState: true });
    this.setState({thresholdRefreshState: true});
    this.setState({ fromDateTime: dateString[0] });
    this.setState({ toDateTime: dateString[1] });
    var DiffMins =  Math.abs(new Date(dateString[0]) - new Date(dateString[1])) ;
    var mins= Math.floor((DiffMins/1000)/60);
    this.setState({tsTimeFilterMins:mins});
    console.log('from',dateString[0],'to--',dateString[1], mins)   
    this.createClo2TimeSeriesMapDataByDateRange('n', this.state.selectedDeviceIdValueState, dateString[0], dateString[1]);
    this.createModeTimeSeriesMapDataByDateRange('n', this.state.selectedDeviceIdValueState, dateString[0], dateString[1]);

  };

  async createClo2TimeSeriesMapDataByDateRange(onMount, deviceSelected, fromDate, toDate) {
    
    let dateLabelTimeSeriesMap = [];
    let clo2ValuesTimeSeriesMap = [];
    let shotSizeValuesTimeSeriesMapTest = [];
    let humidityTimeSeriesMap = [];
    let _tempCurrentTime = moment.utc(moment(toDate)).format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
    let _tempFromTime = moment.utc(moment(fromDate)).format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";    
    console.log('fromTime:: ', _tempFromTime, "  _tempCurrentTime:: ", _tempCurrentTime);
    console.log('refresh after exec in date range---', this.state.selectedRefreshValue, this.state.refreshIntervalState)
    await DeviceTelemetryService.getHistoricalTelemetryByDevIdAccIdTime(deviceSelected, _tempFromTime, _tempCurrentTime, 2200).then((resTimeSeries) => {
      //  console.log('resTimeSeries:: ',resTimeSeries);
      this.setState({ datagridTelemetryMapState: resTimeSeries });
      this.setState({ tableDataRefreshState: false});
      if(onMount == 'n'){
        //var avgClO2Data = TelemetrySharedFunction.getAvgClO2Data(resTimeSeries);
        //this.setState({avgClO2Value:avgClO2Data});
      }
      for (const dataObj of resTimeSeries) {
        dateLabelTimeSeriesMap.push(dataObj.eventDate);
        clo2ValuesTimeSeriesMap.push([dataObj.eventDate,Math.round(dataObj.clo2.value)]);
        humidityTimeSeriesMap.push([dataObj.eventDate,Math.round(dataObj.humidity.value)]);
        if (dataObj.generated == true) {
          //console.log('lastShot:: ',dataObj.lastShotSize.value);
          shotSizeValuesTimeSeriesMapTest.push([dataObj.eventDate,Math.round(dataObj.lastShotSize.value)]);
        } else {
          shotSizeValuesTimeSeriesMapTest.push([dataObj.eventDate,null]); //-1 or plot 0.1 start y-axis
        }
      }
      this.setState({tsLabel:dateLabelTimeSeriesMap});
      this.setState({tsClo2Data:clo2ValuesTimeSeriesMap});
      this.setState({tsHumidityData:humidityTimeSeriesMap});
      this.setState({tsShotSizeData:shotSizeValuesTimeSeriesMapTest});
      console.log('dateLabel:: ', dateLabelTimeSeriesMap);

      this.setState({ timeSeriesRefreshState: false });      
    });
  }

  async createModeTimeSeriesMapDataByDateRange(onMount, deviceSelected, fromDate, toDate) {
    //console.log("deep diving dynamo to get new data for timeseries chart");
     try {
      // console.log('fromDate--: ', fromDate, "--toDate-- ", toDate);
      let _tempCurrentTime = moment.utc(moment(toDate)).format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
      let _tempFromTime = moment.utc(moment(fromDate)).format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";

      await DeviceStateService.getStateTelemetryDataByAccIdDevIdTime(deviceSelected, _tempFromTime, _tempCurrentTime, 2200).then((resTimeSeries) => {
        console.log('queryData', resTimeSeries);
        if (resTimeSeries.length === 0) {
          console.log('in if length 0')
          DeviceStateService.getTopStateTelemetryByDevIdAccId(deviceSelected, 10).then((top10Timeseries) => {          
            if (top10Timeseries.length === 0) {    
                                  
            }
            else {
              this.createModeChartData(top10Timeseries, _tempFromTime, _tempCurrentTime);
            }
            
          });
        }
        else if (resTimeSeries.length === 1) {
          console.log('in length 1 condition');
          let mergedArray = resTimeSeries;
          let eventDate= moment.utc(moment(mergedArray[0].eventDate)).format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
          if(eventDate > _tempFromTime){
            console.log('in if new',eventDate,'--',_tempFromTime);
             DeviceStateService.getStateTelemetryDataByAccIdDevIdTimeAndLimit(deviceSelected, _tempFromTime, 1).then((previousState) => {
              mergedArray.push.apply(mergedArray,previousState);              
            });
            setTimeout(() => {this.createModeChartDataForSingleData('date',mergedArray,_tempFromTime,_tempCurrentTime)},1000);
          }
          else{           
            this.createModeChartData(resTimeSeries, _tempFromTime, _tempCurrentTime);
          } 
        }
        else if (resTimeSeries.length === 2) {
          console.log('in length 2 condition');
          let mergedArray = resTimeSeries;
          let eventDate= moment.utc(moment(resTimeSeries[1].eventDate)).format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
          if(eventDate > _tempFromTime){
            console.log('in length 2 condition if');
            DeviceStateService.getStateTelemetryDataByAccIdDevIdTimeAndLimit(deviceSelected, _tempFromTime, 1).then((previousState) => {
              mergedArray.push.apply(mergedArray,previousState);              
            });
            setTimeout(() => {this.createModeChartDataForMultipleData('date',mergedArray,_tempFromTime,_tempCurrentTime)},1000);
          }
          else{
            this.createModeChartDataForDualData('date',resTimeSeries,_tempFromTime,_tempCurrentTime)
          }
        }
        else {
          console.log('in length 3 condition', resTimeSeries,resTimeSeries.length)
          let mergedArray = resTimeSeries;
          let eventDate= moment.utc(moment(resTimeSeries[resTimeSeries.length - 1].eventDate)).format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
          if(eventDate > _tempFromTime){
            console.log('in length 3 condition if',eventDate , '>', _tempFromTime);
            DeviceStateService.getStateTelemetryDataByAccIdDevIdTimeAndLimit(deviceSelected, _tempFromTime, 1).then((previousState) => {
              mergedArray.push.apply(mergedArray,previousState);              
            });
            setTimeout(() => {this.createModeChartDataForMultipleData('date',mergedArray,_tempFromTime,_tempCurrentTime)},1000);
          }
          else{
           this.createModeChartDataForMultipleData('date',resTimeSeries,_tempFromTime,_tempCurrentTime);
          }          
       }
       this.setState({ stateChartRefreshState: false });
       this.setState({thresholdRefreshState: false});

      });
      setTimeout(() => {
        this.setState({ modeChartHeight: 201 });
      }, 3000);
    } catch (err) {
      console.log('error state map: ', err);
    }

  }

  onChangeFilterDateTime = (e) => {
    this.setState({typeOfDateFilter: e});
    this.setState({ stateChartRefreshState: true });
    this.setState({ timeSeriesRefreshState: true });
    this.setState({ thresholdRefreshState: true });
    
    
    clearInterval(this.clearIntervalTelemetryData);
    if (e === 'time') {
      console.log('onChangeFilterDateTime:: if');

      this.setState({ refreshIntervalState: 30000 });
      this.setState({ selectedRefreshValue: "30s" });
      this.setState({ timeFilter: 'true' });
      this.setState({ selectedTimeFilterValueState: 2 });
      //this.setState({avgClO2RangeState:true});
      this.setState({tsTimeFilterMins:2});
      this.createClo2TimeSeriesMapData('n', this.state.selectedDeviceIdValueState, 2);
      this.createModeTimeSeriesArrayData('n', this.state.selectedDeviceIdValueState, 2);
    } else {
      console.log('onChangeFilterDateTime:: else');
      this.setState({ refreshIntervalState: 0 });
      this.setState({ selectedRefreshValue: "Off" });
      this.setState({ timeFilter: 'false' });
      this.setState({tsTimeFilterMins:2});
      //this.setState({avgClO2RangeState:false});
      clearInterval(this.clearIntervalTelemetryData);
      this.createClo2TimeSeriesMapDataByDateRange('n', this.state.selectedDeviceIdValueState,
        moment().subtract(2, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z", moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z");
      this.createModeTimeSeriesMapDataByDateRange('n', this.state.selectedDeviceIdValueState,
        moment().subtract(2, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z", moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z");
      console.log('refresh after exec---', this.state.selectedRefreshValue, this.state.refreshIntervalState);
    }
  }

  handleMenuClick = (e) => {
    clearInterval(this.clearIntervalTelemetryData); //stop interval
    console.log('e::: ', e.item.props.value, '---', e.item.props.title);
    setTimeout(() => {
      this.setNewInterval(e.item.props.value, e.item.props.title)
    }, 500);
  };

  async setNewInterval(interval, intervalTitle) {
    if (interval === '0') {
      this.setState({ refreshIntervalState: 0 });
      this.setState({ selectedRefreshValue: intervalTitle });
      clearInterval(this.clearIntervalTelemetryData); //stop interval
      clearInterval(this.clearIntervalTelemetryData); //stop interval
    }
    else {
      await (this.setState({ refreshIntervalState: Number(interval) }));
      await (this.setState({ selectedRefreshValue: intervalTitle }));
      await (clearInterval(this.clearIntervalTelemetryData)); //stop interval
      this.clearIntervalTelemetryData = setInterval(() => {
        this.refresh5Sec();
      }, Number(interval));
    }
  }
  menu = (
    <Menu
      onClick={this.handleMenuClick}
      items={[
        {
          title: 'Off',
          label: 'Off',
          key: '1',
          value: '0',
        },
        {
          title: '10s',
          label: '10s',
          key: '2',
          value: '10000',
        },
        {
          title: '30s',
          label: '30s',
          key: '3',
          value: '30000',
        },
        {
          title: '1m',
          label: '1m',
          key: '4',
          value: '60000',
        },
        {
          title: '15m',
          label: '15m',
          key: '5',
          value: '900000',
        }
      ]}
    />
  );


  getTelemetryData = () => {
    this.setState({ deviceMapState: [] });
    this.setState({ selectedTimeRange: [] });
    DeviceService.getDevicesByAccId().then((res) => {      
      let baroP = res[0].barometricPressure * 0.02953;
      this.setState({ clo2DonutValue: Math.round(res[0].clo2) });
      this.setState({ humidityDonutValue: Math.round(res[0].humidity) });
      this.setState({ temperatureDonutValue: Math.round(res[0].temperature) });
      this.setState({ barometricDonutValue: Math.round(baroP) });
      this.setState({cartidgeLevel:Math.floor(Math.random() * (98 - 96 + 1)) + 96})
      this.setState({ latestUdi: res[0].udi });
      this.setState({ tabTitle: res[0].metadata.name });
      if (res[0].target === null || res[0].target === 0) {
        this.setState({ target: 50 });
      }
      else {
        let target = res[0].target * (30 / 100);
        this.setState({ target: res[0].target + target });
      }
      this.getDeviceDetails(res);
      this.populateDeviceListFilter(res);
      // //this.getAvgClO2DataFor8Hrs(res[0].id,moment().subtract(8, 'h').utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z",
      // moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z");
      this.setState({ defaultTimeFilterKeyState: "Now" });
      this.setState({ defaultTimeFilterValueState: 2 });
      this.setState({ selectedDeviceIdValueState: res[0].id });
      this.setState({ selectedTimeFilterValueState: 2 });
      this.createClo2TimeSeriesMapData('y', res[0].id, this.state.defaultTimeFilterValueState);
      this.createModeTimeSeriesArrayData('y', res[0].id, this.state.defaultTimeFilterValueState);
      //color all the donuts based on threshold
      this.setState({thresholdRefreshState:false});
    });
  }

  // async getAvgClO2DataFor8Hrs(deviceId,fromDate,toDate){
  //   try{
  //     await TelemetrySharedFunction.getClO2DataByDeviceIdAndDateTime(deviceId,fromDate,toDate).then((avgClO2Data) =>{
  //       console.log('avgClO2Data-8hrs:::: ',avgClO2Data);
  //       this.setState({avgClO2Value:avgClO2Data});
  //     });
  //   }
  //   catch(e){
  //     console.log('getAvgClO2DataFor8Hrs:',e)
  //   }
  // }

  getDeviceDetails = (res) => {
    let hierarchy = [];
    let hierarchyList = [];
    let hierarchyResult = '';
    if (res[0].metadata != null) {
      this.setState({ mode: res[0].mode });
      this.getMode(res[0].mode);
      this.setState({ deviceMetaData: res[0].metadata })
      if (res[0].metadata.name != null) {
        this.setState({ nickname: res[0].metadata.name });
      }
      else {
        this.setState({ nickname: "None" });
      }
      if (res[0].metadata.location != null) {
        if (res[0].metadata.parentHierarchy != "") {
          hierarchy.push(res[0].metadata.parentHierarchy.split('#'));

          //console.log('hierarchy',hierarchy,hierarchy[0].length)
          for (let i = 1; i < hierarchy[0].length; i++) {
            for (let j = 0; j < this.state.locationDataState.length; j++) {
              if (hierarchy[0][i] === this.state.locationDataState[j].id) {
                hierarchyList.push({ "value": this.state.locationDataState[j].value });
                hierarchyResult = hierarchyResult.concat(this.state.locationDataState[j].value + '/')

              }
            }

          }
        }
        else {
          hierarchyResult = 'None/';
        }

        hierarchyResult = hierarchyResult.slice(0, -1);
        this.setState({ location: hierarchyResult });
        this.setState(prevState => ({
          deviceMetaData: {
            ...prevState.deviceMetaData,
            location: hierarchyResult
          }
        }));
      }
      else {
        this.setState({ location: "None" });
        this.setState(prevState => ({
          deviceMetaData: {
            ...prevState.deviceMetaData,
            location: "None"
          }
        }));
      }
    }
    else {
      this.setState({ mode: "NONE" });
      this.setState({ name: "None" });
      this.setState({ location: "None" });
      this.setState({ deviceMetaData: [] })
    }
    var listOfModes = ["SENSE_ONLY", "OPERATIONAL", "STANDBY", "RESEARCH", "OFFLINE"];
    var modeIndex = listOfModes.indexOf(this.state.mode);
    listOfModes.splice(modeIndex, 1);
    this.setState({ modesList: listOfModes });
  }

  getMode = (mode) => {
    const modeColorIconMapping = {
      SENSE_ONLY: { modeColor: '#00CCCC', modeIcon: 'mdi mdi-24px mdi-access-point' },
      STANDBY: { modeColor: '#FFD700', modeIcon: 'mdi mdi-24px mdi-power-standby' },
      OPERATIONAL: { modeColor: '#5565AF', modeIcon: 'mdi mdi-24px mdi-cog-transfer' },
      RESEARCH: { modeColor: '#7159A6', modeIcon: 'mdi mdi-24px mdi-book-search-outline' },
      OFFLINE: { modeColor: '#FE8479', modeIcon: 'mdi mdi-24px mdi-power-plug-off' },
      UNKNOWN: { modeColor: '#000000', modeIcon: 'mdi mdi-24px mdi-power-standby' }, 
    }

    if (modeColorIconMapping.hasOwnProperty(mode)){
      this.setState({ modeColor: modeColorIconMapping[mode].modeColor });
      this.setState({ modeIcon: modeColorIconMapping[mode].modeIcon });
    }
    else{
      this.setState({ modeColor: modeColorIconMapping["UNKNOWN"].modeColor });
      this.setState({ modeIcon: modeColorIconMapping["UNKNOWN"].modeIcon });
    }
    this.setState({ modeState: mode });
  }
  getDeviceDetailsByFilter = (res) => {
    let hierarchy = [];
    let hierarchyList = [];
    let hierarchyResult = '';
    if (res[0].metadata != null) {
      this.setState({ mode: res[0].mode });
      this.getMode(res[0].mode);
      this.setState({ deviceMetaData: res[0].metadata })
      if (res[0].metadata.name != null) {
        this.setState({ nickname: res[0].metadata.name });
      }
      else {
        this.setState({ nickname: "None" });
      }
      if (res[0].metadata.location != null) {
        if (res[0].metadata.parentHierarchy != "") {
          hierarchy.push(res[0].metadata.parentHierarchy.split('#'));
          for (let i = 1; i < hierarchy[0].length; i++) {
            for (let j = 0; j < this.state.locationDataState.length; j++) {
              if (hierarchy[0][i] === this.state.locationDataState[j].id) {
                hierarchyList.push({ "value": this.state.locationDataState[j].value });
                hierarchyResult = hierarchyResult.concat(this.state.locationDataState[j].value + '/')

              }
            }

          }
        }
        else{
          hierarchyResult = 'None/';
        }

        hierarchyResult = hierarchyResult.slice(0, -1);
        this.setState({ location: hierarchyResult });
        console.log('hierarchyResult:: ',hierarchyResult)
        this.setState(prevState => ({
          deviceMetaData: {
            ...prevState.deviceMetaData,
            location: hierarchyResult
          }
        }));
      }
      else {
        this.setState({ location: "None" });
        this.setState(prevState => ({
          deviceMetaData: {
            ...prevState.deviceMetaData,
            location: "None"
          }
        }));
      }
    }
    else {
      this.setState({ mode: "None" });
      this.setState({ name: "None" });
      this.setState({ location: "None" });
      this.setState({ deviceMetaData: [] })
    }
    var listOfModes = ["SENSE_ONLY", "OPERATIONAL", "STANDBY", "RESEARCH", "OFFLINE"];
    var modeIndex = listOfModes.indexOf(this.state.mode);
    listOfModes.splice(modeIndex, 1);
    this.setState({ modesList: listOfModes });
  }

  getDeviceTelemetryByFilter = (deviceId) => {
    DeviceService.getCurrentTelemetryByDevIdAccId(deviceId).then((resultByFilter) => {
 
      this.setState({ clo2DonutValue: Math.round(resultByFilter[0].clo2) });
      this.setState({ humidityDonutValue: Math.round(resultByFilter[0].humidity) });
      this.setState({ temperatureDonutValue: Math.round(resultByFilter[0].temperature) });
      this.setState({ barometricDonutValue: Math.round((resultByFilter[0].barometricPressure * 0.02953)) });
      this.setState({cartidgeLevel:Math.floor(Math.random() * (98 - 96 + 1)) + 96})
      this.setState({ latestUdi: resultByFilter[0].udi });
      this.setState({ tabTitle: resultByFilter[0].metadata.name });
      if (resultByFilter[0].target === null || resultByFilter[0].target === 0) {
        this.setState({ target: 50 });

      }
      else {
        let target = resultByFilter[0].target * (30 / 100);

        this.setState({ target: resultByFilter[0].target + target });
      }


      this.getDeviceDetailsByFilter(resultByFilter);
      this.setState({ deviceTelemetryByFilterList: resultByFilter });
    })
  }

  getMasterLocationData = () => {
    LocationService.getLocationByFilter().then((locationByFilter) => {
      this.setState({ locationDataState: locationByFilter })
    })
  }

  populateDeviceListFilter = (deviceList) => {
    let _tempDeviceMap = [];
    for (const deviceDataObj of deviceList) {
      if (_tempDeviceMap.find(x => x.udi === deviceDataObj.udi)) {
      }
      else {
        _tempDeviceMap.push({ "deviceName": deviceDataObj.udi, "deviceId": deviceDataObj.id, "nickname": deviceDataObj.metadata.name });
      }
    }
    this.setState({ latestDeviceIdState: _tempDeviceMap[0].deviceId });
    this.setState({ deviceMapState: _tempDeviceMap });
    this.setState({ latestDeviceNameState: _tempDeviceMap[0].nickname });
  }


  getLimitOnTimeFilter(timeRangeSelected) {
    let _limit = '';
    if (timeRangeSelected == 2) {
      _limit = 11;
    }
    else if (timeRangeSelected == 5) {
      _limit = 27;
    }
    else if (timeRangeSelected == 15) {
      _limit = 81;
    }
    else if (timeRangeSelected == 30) {
      _limit = 163;
    }
    else if (timeRangeSelected == 60) {
      _limit = 327;
    }
    else if (timeRangeSelected == 180) {
      _limit = 982;
    }
    else if (timeRangeSelected == 360) {
      _limit = 1964;
    }
    else if (timeRangeSelected == 720) {
      _limit = 3927;
    }
    else if (timeRangeSelected == 1440) {
      _limit = 7855;
    }
    else if (timeRangeSelected == 2880) {
      _limit = 15709;
    }
    else if (timeRangeSelected == 10080) {
      _limit = 54982;
    }
    else {
    }
    return _limit;
  }

  getRefreshIntervalOnTimeFilter(timeRangeSelected) {
    var _intervalStateTempData = this.state.refreshIntervalState;
    console.log('in getRefreshIntervalOnTImefilter-', timeRangeSelected)
    clearInterval(this.clearIntervalTelemetryData);

    if (timeRangeSelected > 0 && timeRangeSelected < 60){
      _intervalStateTempData = 30000;
      this.setState({ refreshIntervalState: 30000 });
      this.setState({ selectedRefreshValue: '30s' });
    }
    else if (timeRangeSelected >= 60 && timeRangeSelected < 360){
      _intervalStateTempData = 60000;
      this.setState({ refreshIntervalState: 60000 });
      this.setState({ selectedRefreshValue: '1m' });
    }
    else if(timeRangeSelected >= 360){
      _intervalStateTempData = 900000;
      this.setState({ refreshIntervalState: 900000 });
      this.setState({ selectedRefreshValue: '15m' });
    } 
    else{

    }

    this.clearIntervalTelemetryData = setInterval(() => {
      this.refresh5Sec();
    }, _intervalStateTempData);
  
  }

  getMinMaxValue=(data)=>{
    return data.map(d => d.clo2.value);
  }

  async createClo2TimeSeriesMapData(onMount, deviceSelected, timeRangeSelected) {

    let dateLabelTimeSeriesMap = [];
    let clo2ValuesTimeSeriesMap = [];
    let shotSizeValuesTimeSeriesMapTest = [];
    let humidityTimeSeriesMap = [];
    let _tempCurrentTime = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
    let _tempFromTime = moment().subtract(timeRangeSelected, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";

    try{
      await DeviceTelemetryService.getHistoricalTelemetryByDevIdAccIdTime(deviceSelected, _tempFromTime, _tempCurrentTime, 2200).then((resTimeSeries) => {

        // this.setAvgTimeRange(timeRangeSelected);
        this.setState({ datagridTelemetryMapState: resTimeSeries });
        this.setState({ tableDataRefreshState: false});
        if(onMount == 'n'){
          var avgClO2Data = TelemetrySharedFunction.getAvgClO2Data(resTimeSeries);
          if(resTimeSeries.length != 0){
            this.setState({minClO2Value:Math.min(...this.getMinMaxValue(resTimeSeries))})
            this.setState({maxClO2Value:Math.max(...this.getMinMaxValue(resTimeSeries))})
          }
          else{
            this.setState({minClO2Value:0})
            this.setState({maxClO2Value:0})
          }
          
          //this.setState({avgClO2Value:avgClO2Data});
        }
        for (const dataObj of resTimeSeries) {
          dateLabelTimeSeriesMap.push(dataObj.eventDate);
          clo2ValuesTimeSeriesMap.push([new Date(dataObj.eventDate).getTime(),Math.round(dataObj.clo2.value)]);
          humidityTimeSeriesMap.push([new Date(dataObj.eventDate).getTime(),Math.round(dataObj.humidity.value)]);
          if (dataObj.generated == true) {
            shotSizeValuesTimeSeriesMapTest.push([new Date(dataObj.eventDate).getTime(),Math.round(dataObj.lastShotSize.value)]);
          } else {
            shotSizeValuesTimeSeriesMapTest.push([new Date(dataObj.eventDate).getTime(),null]); //-1 or plot 0.1 start y-axis
          }
        }

        this.setState({tsLabel:dateLabelTimeSeriesMap});
        this.setState({tsClo2Data:clo2ValuesTimeSeriesMap});
        this.setState({tsHumidityData:humidityTimeSeriesMap});
        this.setState({tsShotSizeData:shotSizeValuesTimeSeriesMapTest});

        

        
        this.setState({ timeSeriesRefreshState: false });
      });
    }
    catch(err){
      this.setState({ tableDataRefreshState: false });
      this.setState({ timeSeriesRefreshState: false });
      this.setState({ stateChartRefreshState: false });
      this.setState({thresholdRefreshState: false});
      console.log('Error Occured createClo2TimeSeriesMapData:: ',err); return null;
    }
  }

  // setAvgTimeRange = (timeRangeinMins)=>{
  //   if(timeRangeinMins === 5){
  //     this.setState({avgClO2RangeValue:'[5m]'});
  //   }
  //   else  if(timeRangeinMins === 15){
  //     this.setState({avgClO2RangeValue:'[15m]'});
  //   }
  //   else  if(timeRangeinMins === 30){
  //     this.setState({avgClO2RangeValue:'[30m]'});
  //   }
  //   else  if(timeRangeinMins === 60){
  //     this.setState({avgClO2RangeValue:'[1h]'});
  //   }
  //   else  if(timeRangeinMins === 180){
  //     this.setState({avgClO2RangeValue:'[3h]'});
  //   }
  //   else  if(timeRangeinMins === 360){
  //     this.setState({avgClO2RangeValue:'[6h]'});
  //   }
  //   else  if(timeRangeinMins === 720){
  //     this.setState({avgClO2RangeValue:'[12h]'});
  //   }
  //   else  if(timeRangeinMins === 1440){
  //     this.setState({avgClO2RangeValue:'[24h]'});
  //   }
  // }

  async createModeTimeSeriesArrayData(onMount, deviceSelected, timeRangeSelected) {
  
    try {
      let _tempCurrentTime = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
      let _tempFromTime = moment().subtract(timeRangeSelected, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
      await DeviceStateService.getStateTelemetryDataByAccIdDevIdTime(deviceSelected, _tempFromTime, _tempCurrentTime, 2200).then((resTimeSeries) => {
        console.log('queryData', resTimeSeries,resTimeSeries.length);
        if (resTimeSeries.length === 0) {          
           DeviceStateService.getTopStateTelemetryByDevIdAccId(deviceSelected, 10).then((top10Timeseries) => {          
            if (top10Timeseries.length === 0) {    
              console.log("top10Timeseries", top10Timeseries)                    
            }
            else {
              console.log("top10Timeseries", top10Timeseries)
              this.createModeChartData(top10Timeseries, _tempFromTime, _tempCurrentTime);
            }
          });
        }
        else if (resTimeSeries.length === 1) {
          console.log('in length 1 condition');
          let mergedArray = resTimeSeries;
          let eventDate= moment.utc(moment(mergedArray[0].eventDate)).format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
          if(eventDate > _tempFromTime){
            console.log('in if new',eventDate,'--',_tempFromTime);
             DeviceStateService.getStateTelemetryDataByAccIdDevIdTimeAndLimit(deviceSelected, _tempFromTime, 1).then((previousState) => {
              mergedArray.push.apply(mergedArray,previousState);              
            });
            setTimeout(() => {this.createModeChartDataForSingleData('time',mergedArray,_tempFromTime,_tempCurrentTime)},1000);
          }
          else{           
            this.createModeChartData(resTimeSeries, _tempFromTime, _tempCurrentTime);
          }          
        }
        else if (resTimeSeries.length === 2) {
          console.log('in length 2 condition');
          let mergedArray = resTimeSeries;
          let eventDate= moment.utc(moment(resTimeSeries[1].eventDate)).format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
          if(eventDate > _tempFromTime){
            console.log('in length 2 condition if');
            DeviceStateService.getStateTelemetryDataByAccIdDevIdTimeAndLimit(deviceSelected, _tempFromTime, 1).then((previousState) => {
              mergedArray.push.apply(mergedArray,previousState);              
            });
            setTimeout(() => {this.createModeChartDataForMultipleData('time',mergedArray,_tempFromTime,_tempCurrentTime)},1000);
          }
          else{
            this.createModeChartDataForDualData(resTimeSeries,_tempFromTime,_tempCurrentTime)
          }
        }
        else {
           console.log('in length 3 condition', resTimeSeries,resTimeSeries.length)
           let mergedArray = resTimeSeries;
           let eventDate= moment.utc(moment(resTimeSeries[resTimeSeries.length - 1].eventDate)).format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
           if(eventDate > _tempFromTime){
             console.log('in length 3 condition if',eventDate , '>', _tempFromTime);
             DeviceStateService.getStateTelemetryDataByAccIdDevIdTimeAndLimit(deviceSelected, _tempFromTime, 1).then((previousState) => {
               mergedArray.push.apply(mergedArray,previousState);              
             });
             setTimeout(() => {this.createModeChartDataForMultipleData('time',mergedArray,_tempFromTime,_tempCurrentTime)},1000);
           }
           else{
            this.createModeChartDataForMultipleData('time',resTimeSeries,_tempFromTime,_tempCurrentTime);
           }          
        }
        this.setState({ stateChartRefreshState: false });
        this.setState({thresholdRefreshState:false});
        
      });
      setTimeout(() => {
        this.setState({ modeChartHeight: 201 });
      }, 3000);
    } catch (err) {
      this.setState({ timeSeriesRefreshState: false });
      this.setState({ stateChartRefreshState: false });
      this.setState({thresholdRefreshState:false});
      console.log('error state map: ', err);
    }
  }


  createModeChartDataForSingleData = (filterType, resTimeSeries, _tempFromTime, toDate) => {

    let toDateTime;
    var fromDate;

    let modeColorDataMappingObject = {
      STANDBY: {
        name: "STANDBY",
        data: [],
        color: "#FFD700"
      },
      SENSE_ONLY: {
        name: "SENSE_ONLY",
        data: [],
        color: "#00CCCC"
      },
      OPERATIONAL: {
        name: "OPERATIONAL",
        data: [],
        color: "#5565AF"
      },
      RESEARCH: {
        name: "RESEARCH",
        data: [],
        color: "#7159A6"
      },
      OFFLINE: {
        name: "OFFLINE",
        data: [],
        color: "#FE8479"
      },
      UNKNOWN: {
        name: "UNKNOWN",
        data: [],
        color: "#BFBFBF"
      },
    }

    console.log('resTimeSeries in single data',resTimeSeries,resTimeSeries.length)

    for (var i = 0; i < (resTimeSeries.length); i++) {
      
      if (i === 1) {
        //fromDate = moment(_tempFromTime).local().format('YYYY-MM-DD HH:mm:ss');
        fromDate = _tempFromTime;
        toDateTime = resTimeSeries[0].eventDate;
      }
      else if (i === 0) {
        fromDate = resTimeSeries[0].eventDate;
        if(filterType==='time'){
          toDateTime = new Date().toISOString()
        }
        else{
          console.log('toDate',toDate)
          toDateTime = toDate;
        }      
      }

      if (modeColorDataMappingObject.hasOwnProperty(resTimeSeries[i].mode)) {
        const mode = resTimeSeries[i].mode;
        modeColorDataMappingObject[mode].data.push({
          x: 'Mode',
          y: [new Date(fromDate).getTime(), new Date(toDateTime).getTime()]
        })
      }
    }

    const modeSeriesData = [modeColorDataMappingObject.STANDBY, modeColorDataMappingObject.SENSE_ONLY, modeColorDataMappingObject.OPERATIONAL, modeColorDataMappingObject.RESEARCH, modeColorDataMappingObject.OFFLINE, modeColorDataMappingObject.UNKNOWN];
    
    console.log('modeSeriesData', modeSeriesData);
    this.setState({ stateChartSeries: modeSeriesData });
  }

  createModeChartDataForDualData = (filterType,resTimeSeries, _tempFromTime, _tempCurrentTime) => {    
    let toDateTime;
    var fromDate;   

    let modeColorDataMappingObject = {
      STANDBY: {
        name: "STANDBY",
        data: [],
        color: "#FFD700"
      },
      SENSE_ONLY: {
        name: "SENSE_ONLY",
        data: [],
        color: "#00CCCC"
      },
      OPERATIONAL: {
        name: "OPERATIONAL",
        data: [],
        color: "#5565AF"
      },
      RESEARCH: {
        name: "RESEARCH",
        data: [],
        color: "#7159A6"
      },
      OFFLINE: {
        name: "OFFLINE",
        data: [],
        color: "#FE8479"
      },
      UNKNOWN: {
        name: "UNKNOWN",
        data: [],
        color: "#BFBFBF"
      },
    }

    for (var i = 0; i < (resTimeSeries.length); i++) {
      if (i === 1) {
        fromDate = _tempFromTime;
        toDateTime = resTimeSeries[0].eventDate;
      }
      else if (i === 0) {
        fromDate = resTimeSeries[0].eventDate;
        if(filterType==='time'){
          toDateTime = new Date().toISOString()
        }
          else{
            toDateTime = _tempCurrentTime
          }
      }

      if (modeColorDataMappingObject.hasOwnProperty(resTimeSeries[i].mode)) {
        const mode = resTimeSeries[i].mode;
        modeColorDataMappingObject[mode].data.push({
          x: 'Mode',
          y: [new Date(fromDate).getTime(), new Date(toDateTime).getTime()]
        })
      }
      
    }

    const modeSeriesData = [modeColorDataMappingObject.STANDBY, modeColorDataMappingObject.SENSE_ONLY, modeColorDataMappingObject.OPERATIONAL, modeColorDataMappingObject.RESEARCH, modeColorDataMappingObject.OFFLINE, modeColorDataMappingObject.UNKNOWN];

    this.setState({ stateChartSeries: modeSeriesData });
  }

  createModeChartDataForMultipleData = (filterType, resTimeSeries, _tempFromTime, _tempCurrentTime) => {
      
    var fromDate;
    var toDate;

    let modeColorDataMappingObject = {
      STANDBY: {
        name: "STANDBY",
        data: [],
        color: "#FFD700"
      },
      SENSE_ONLY: {
        name: "SENSE_ONLY",
        data: [],
        color: "#00CCCC"
      },
      OPERATIONAL: {
        name: "OPERATIONAL",
        data: [],
        color: "#5565AF"
      },
      RESEARCH: {
        name: "RESEARCH",
        data: [],
        color: "#7159A6"
      },
      OFFLINE: {
        name: "OFFLINE",
        data: [],
        color: "#FE8479"
      },
      UNKNOWN: {
        name: "UNKNOWN",
        data: [],
        color: "#BFBFBF"
      },
    }

    for (var i = 0; i < (resTimeSeries.length); i++) {
      if (i === 0) {
        fromDate = resTimeSeries[0].eventDate;
        //var currentDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if(filterType==='time'){
          toDate = new Date().toISOString();
        }
        else{
          toDate = _tempCurrentTime;
        }
      }
      else if (i > 0 && i < resTimeSeries.length - 1) {
        fromDate = resTimeSeries[i].eventDate;
        toDate = resTimeSeries[i-1].eventDate;
      }
      else if (i === resTimeSeries.length - 1) {
        fromDate = _tempFromTime;
        toDate = resTimeSeries[i-1].eventDate;
      }
    
      if (modeColorDataMappingObject.hasOwnProperty(resTimeSeries[i].mode)) {
        const mode = resTimeSeries[i].mode;
        modeColorDataMappingObject[mode].data.push({
          x: 'Mode',
          y: [new Date(fromDate).getTime(), new Date(toDate).getTime()]
        })
      }
    }

    const modeSeriesData = [modeColorDataMappingObject.STANDBY, modeColorDataMappingObject.SENSE_ONLY, modeColorDataMappingObject.OPERATIONAL, modeColorDataMappingObject.RESEARCH, modeColorDataMappingObject.OFFLINE, modeColorDataMappingObject.UNKNOWN];

    console.log('modeSeriesData --+--', modeSeriesData);
    this.setState({ stateChartSeries: modeSeriesData });
  }

  createModeChartData = (top10Timeseries, fromDate, toDate) => {
    let modeSeriesData = []
    const modeColorMapping = [
      {mode: "STANDBY", color: "#FFD700"},
      {mode: "SENSE_ONLY", color: "#00CCCC"},
      {mode: "OPERATIONAL", color: "#5565AF"},
      {mode: "RESEARCH", color: "#7159A6"},
      {mode: "OFFLINE", color: "#FE8479"},
      {mode: "UNKNOWN", color: "#BFBFBF"}
    ]

    const mode = top10Timeseries[0].mode;
    for (var i = 0; i < modeColorMapping.length; i++){
      
      if (mode == modeColorMapping[i].mode){
        const dataArray = {
          x: 'Mode',
          y: [new Date(fromDate).getTime(), new Date(toDate).getTime()]
        } 
        modeSeriesData.push({
          name: mode,
          data: [dataArray],
          color: modeColorMapping[i].color
        })
      } 
      else{
        modeSeriesData.push({
          name: modeColorMapping[i].mode,
          data: [],
          color: modeColorMapping[i].color
        })
      }
    }

    this.setState({ stateChartSeries: modeSeriesData });
    console.log("mode series: ", modeSeriesData)
    setTimeout(() => {
      this.setState({ modeChartHeight: 201 });
    }, 3000);

  }

  handleDeviceFilterChange = (e) => {
    
    this.setState({ deviceId: e });
    this.setState({ latestDeviceIdState: e });
    this.setState({ selectedDeviceIdValueState: e });
    this.setState({thresholdRefreshState:true});
    this.setState({ timeSeriesRefreshState: true });
    this.setState({ stateChartRefreshState: true });
    this.setState({ tableDataRefreshState: true });

    this.getDeviceTelemetryByFilter(e);
    if (this.state.timeFilter == 'true') {
      this.createClo2TimeSeriesMapData('n', e, this.state.selectedTimeFilterValueState);
      this.createModeTimeSeriesArrayData('n', e, this.state.selectedTimeFilterValueState); 
     
    } else {
      this.createClo2TimeSeriesMapDataByDateRange('n', e, this.state.fromDateTime, this.state.toDateTime);
      this.createModeTimeSeriesMapDataByDateRange('n', e, this.state.fromDateTime, this.state.toDateTime);

    }
    
  }

  handleTimeFilterChange = (e) => {
    this.setState({ thresholdRefreshState: true });
    this.setState({ tableDataRefreshState: true });
    this.setState({ timeSeriesRefreshState: true });
    this.setState({ stateChartRefreshState: true });  
    console.log('e time filter value--',e)
   
    this.getDeviceTelemetryByFilter(this.state.selectedDeviceIdValueState);
    this.setState({ selectedTimeFilterValueState: e });
    this.setState({ tsTimeFilterMins: e });    
    //from date to date setState
    clearInterval(this.clearIntervalTelemetryData);
    clearInterval(this.clearIntervalTelemetryData);
    setTimeout(() => {
      this.getRefreshIntervalOnTimeFilter(e);
    }, 200);
   
    this.createClo2TimeSeriesMapData('n', this.state.selectedDeviceIdValueState, e);
    this.createModeTimeSeriesArrayData('n', this.state.selectedDeviceIdValueState, e);
  };

  deviceMetaModalOpen = () => {
    console.log('clicken open:: ');
    clearInterval(this.clearIntervalTelemetryData);  //stopping refresh intervals
    console.log("device meta data: ", this.state.deviceMetaData);
    if (this.state.deviceMetaData.device){
      this.setState({ commsVersion: this.state.deviceMetaData.device.commsVersion });
      this.setState({ controllerVersion: this.state.deviceMetaData.device.controllerVersion});
    }
    
    this.setState({ open: true });
  }

  otaUpdateModalOpen = (firmwareType) => {
    console.log('modal open ota');
    if (firmwareType === 'comms') {
      clearInterval(this.clearIntervalTelemetryData);
      this.setState({ visible: true });
      this.setState({ firmwareType: 'comms' });
      this.getTargetFirmwareComms();
    }
    else {
      clearInterval(this.clearIntervalTelemetryData);
      this.setState({ visible: true });
      this.setState({ firmwareType: 'controls' });
      this.getTargetFirmwareControls();
    }
  }

  handleResponseModal = (childData) => {
    
    this.setState({ open: childData });
    this.clearIntervalTelemetryData = setInterval(() => { //starting refresh intervals on modal close
      this.refresh5Sec();
    }, this.state.refreshIntervalState);
  }

  handleOtaUpdateModal = (childData) => {
    console.log('modal close');
    this.setState({ visible: childData });
    this.clearIntervalTelemetryData = setInterval(() => { //starting refresh intervals on modal close
      this.refresh5Sec();
    }, this.state.refreshIntervalState);
  }

  getTargetFirmwareComms = () => {
    console.log('coms')
    SharedService.fetchS3ObjectsOTABucket('comms').then((targetFirmwareListData) => {
      console.log('targetFirmwareList:: ', targetFirmwareListData);
      this.setState({ targetFirmwareList: targetFirmwareListData })
    });
  }

  getTargetFirmwareControls = () => {
    console.log('controls')
    SharedService.fetchS3ObjectsOTABucket('controls').then((targetFirmwareListData) => {
      
      this.setState({ targetFirmwareList: targetFirmwareListData })
    });
  }
  refresh5Sec = () => {
    console.log("refreshIntervalState:: ", this.state.refreshIntervalState);
    if (this.state.refreshIntervalState != 0) {
      var _tempDeviceId = 0;
      if (this.state.selectedDeviceIdValueState == "" || this.state.selectedDeviceIdValueState == null) {
        _tempDeviceId = this.state.selectedDeviceIdValueState;
      } else {
        _tempDeviceId = this.state.selectedDeviceIdValueState;
      }
      var _tempSelectedTime = 0;
      if (this.state.selectedTimeFilterValueState == "" || this.state.selectedTimeFilterValueState == null) {
        _tempSelectedTime = 2;
      } else {
        _tempSelectedTime = this.state.selectedTimeFilterValueState;
      }
      this.setState({ tableDataRefreshState: true });
      this.setState({ timeSeriesRefreshState: true });
      this.setState({thresholdRefreshState: true});
      this.getDeviceTelemetryByFilter(_tempDeviceId);
      if (this.state.timeFilter == 'true') {

        this.setState({ timeSeriesRefreshState: true });
        this.setState({ stateChartRefreshState: true });
       
        this.createClo2TimeSeriesMapData("n", _tempDeviceId, _tempSelectedTime);
        this.createModeTimeSeriesArrayData("n", _tempDeviceId, _tempSelectedTime);
      } else {
        if (this.state.onClickDateTimeFilter == false) {
          var fromDateTime = moment().subtract(2, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
          var toDateTime = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
        } else {
          var fromDateTime = this.state.fromDateTime;
          var toDateTime = this.state.toDateTime;
        }
        this.setState({ timeSeriesRefreshState: true });
        this.setState({ stateChartRefreshState: true });
        this.setState({thresholdRefreshState: true});

        this.createClo2TimeSeriesMapDataByDateRange("n", _tempDeviceId, fromDateTime, toDateTime);
        this.createModeTimeSeriesMapDataByDateRange("n", _tempDeviceId, fromDateTime, toDateTime);
      }
    }
    else {
      this.setState({ timeSeriesRefreshState: false });
      // console.log('else refresh::------------------------------');
    }

  }

  sendCommand = (mode) => {
    SharedService.sendCommandForChangeMode(this.state.selectedDeviceIdValueState, this.state.latestUdi, mode).then((res) => {

    });
  }
  setType = (e) => {
    this.setState({ type: e });
  }

  async getUserRole() {
    try {
      await SharedService.getCurrentUser().then((userDataList) => {
        SharedService.getUserByEmailId(userDataList.attributes.email).then((userData) => {
          var userDataList = userData;
          //console.log('userDataList:: ', userDataList)
          if (userDataList === null || userDataList === [] || userDataList.length === 0) {
            this.setState({ userRole: '' });
          } else {
            var userRoleName = userDataList[0].userType;
            //console.log('userDataList:: ', userDataList);
            this.setState({ userRole: userRoleName });
            this.setState({ username: userDataList[0].username })
          }
        });
      });

    } catch (err) {
      console.log('error on DeviceMeta::getUserRole:: ', err);
    }
  }
  toggleMobileFilterBar = () => {
    console.log("Filter bar")
    this.setState({ openMobileFilterBar: !this.state.openMobileFilterBar})
  }
  componentDidMount() {
    this.setState({thresholdRefreshState:true});
    this.setState({ timeSeriesRefreshState: true });
    this.setState({ stateChartRefreshState: true });
    this.getUserRole();

    // this.sendCommand();
    if (deviceIdRouteValue == null) {
      //console.log('if did mount')
      setTimeout(() => {
        this.getTelemetryData();
        this.getMasterLocationData();
      }, 200);
    }
    else {
      //console.log('else did mount')
      this.setRoutingValues(deviceIdRouteValue);
    }
    this.clearIntervalTelemetryData = setInterval(e => this.refresh5Sec(), this.state.refreshIntervalState);
    
  }

  componentWillUnmount() {
    clearInterval(this.clearIntervalTelemetryData);
  }

  toggleProBanner() {
    document.querySelector('.proBanner').classList.toggle("hide");
  }
  setTop = () => {
    this.setState({ top: 60 });
  }

  render() {
    document.title = this.state.tabTitle.toString() + '-' + 'Events.LIVE';
    return (
    
      <div className='container-fluid'>  
        <Affix offsetTop={64.2} onChange={(affixed) => console.log(affixed)}>
          <div className='container-fluid'>        

            <BreadcrumbComponent parent="Dashboard" child="Events" live />
          
            <div className="row">
              <div className="card pe-0">
                <div className="card-body pt-0 pb-0">
                  <div className="row">
                    <div className="col d-flex align-items-center ps-0">
                      <ul className="list-group list-group-horizontal align-items-center">
                        <li className="list-group-item border-0 pe-0">
                          <span className="breadcrumb-label">Name: </span><span className="breadcrumb-metadata">{this.state.nickname}</span>
                        </li>
                        
                        <li className="list-group-item border-0 pe-0">
                          <span className="breadcrumb-label">Location: </span><span className="breadcrumb-metadata">{this.state.location}</span>
                        </li> 
                      </ul>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <div className="toolbar-menu align-self-center">
                        <ul className="list-group list-group-horizontal pe-0 d-flex align-items-center">

                          <li className="list-group-item border-0 ps-1 pe-1">
                          <Select placeholder="Devices"
                            id="device-select" label="Devices" style={{ width: 195 }}
                            defaultValue={this.state.latestDeviceNameState} value={this.state.nickname}
                            onChange={this.handleDeviceFilterChange}>

                            {this.state.deviceMapState.map((make, index) => (
                              <Option key={make.deviceId} value={make.deviceId}>{make.nickname}</Option>
                            ))}
                          </Select>
                          </li>

                          <li className="list-group-item border-0 ps-1 pe-1">
                          <Select placeholder="Date & Time"
                            id="datefilter-select" label="datefilter select" style={{ width: 195 }}
                            defaultValue={this.state.typeOfDateFilter}
                            value={this.state.typeOfDateFilter}
                            onChange={this.onChangeFilterDateTime} >
                            <Option key='time' value='time'>Time</Option>
                            <Option key='datetime' value='datetime'>Date & Time</Option>
                          </Select>
                          </li>

                          <li className="list-group-item border-0 ps-1 pe-1">
                          {
                            this.state.timeFilter === 'true' ?
                            <Select placeholder="Time"
                              id="time-range-select" label="Time" style={{ width: 195 }}
                              defaultValue={2}
                              onChange={this.handleTimeFilterChange} >
                              <Option value={2}>Now</Option>
                              <Option value={5}>Last 5 minutes</Option>
                              <Option value={15}>Last 15 minutes</Option>
                              <Option value={30}>Last 30 minutes</Option>
                              <Option value={60}>Last 1 hour</Option>
                              <Option value={180}>Last 3 hours</Option>
                              <Option value={360}>Last 6 hours</Option>
                              <Option value={720}>Last 12 hours</Option>
                              <Option value={1440}>Last 24 hours</Option>
                            </Select>
                          :
                            <Space direction="vertical" size={12}>
                              <RangePicker
                                showTime={{
                                  format: 'HH:mm',
                                }}
                                value={this.state.selectedDatetime}
                                format="YYYY-MM-DD HH:mm"
                                onChange={this.onChange}
                                allowClear={false}
                                style={{ width: 320 }}
                              />
                            </Space>
                        }
                          </li>

                          <li className="list-group-item border-0 ps-1 pe-1">
                          <Dropdown.Button onClick={this.refresh5Sec} overlay={this.menu} defaultValue="5s" placement="bottom" icon={<DownOutlined />}>
                            <div className="d-inline-flex align-items-center">
                              {this.state.selectedRefreshValue} &nbsp; <SyncOutlined />
                            </div>
                          </Dropdown.Button>
                          </li>

                        </ul>
                      </div>
                      
                      <ul className="list-group list-group-horizontal pe-0 align-items-center">
                        <li className="list-group-item border-0 ps-3 pe-0 li-item">
                          <div className="dropdown dropdown-arrow-none">
                            <DropdownReact style={{ height: "36px", marginTop: "3px" }} size="lg">
                              <DropdownReact.Toggle size="lg" className="btn p-0 float-right text-dark bg-transparent border-0 hide-carret">
                                <i className="mdi mdi-24px mdi-broadcast border-0"></i>
                              </DropdownReact.Toggle>

                              <DropdownReact.Menu>
                                {this.state.username === 'christobel' || this.state.username === 'djorgji' || this.state.username === 'webtest' || this.state.username === 'cmills' || this.state.username === 'datta' || this.state.username === 'vishal' ? <div>
                                  <DropdownReact.Item onClick={() => this.otaUpdateModalOpen('comms')}><i className="mdi mdi-plus-circle-outline mr-2"></i> OTA Comms Update </DropdownReact.Item>
                                  <DropdownReact.Item onClick={() => this.otaUpdateModalOpen('controls')}><i className="mdi mdi-plus-circle-outline mr-2"></i> OTA Controls Update </DropdownReact.Item>  </div> : <div></div>
                                }
                                <DropdownReact.Item className="a-href-highlight-remove" href='/otaHistory'><i className="mdi mdi-file-document-outline"/> <span>OTA History</span></DropdownReact.Item>
                              </DropdownReact.Menu>

                            </DropdownReact>
                          </div>
                        </li>
                        <li className="list-group-item border-0 ps-3 pe-0 li-item">
                          <div className="dropdown dropdown-arrow-none">
                            <DropdownReact style={{ height: "36px", marginTop: "3px" }} size="lg">
                              <DropdownReact.Toggle size="lg" className="btn p-0 float-right text-dark bg-transparent border-0 hide-carret">
                                <i className="mdi mdi-24px mdi-cogs border-0"></i>
                              </DropdownReact.Toggle>

                              <DropdownReact.Menu>
                                <DropdownReact.Item onClick={() => this.sendCommand(this.state.modesList[0])}><i className="mdi mdi-pencil-outline mr-2"></i> Go {this.state.modesList[0]}</DropdownReact.Item>
                                <DropdownReact.Item onClick={() => this.sendCommand(this.state.modesList[1])}><i className="mdi mdi-pencil-outline mr-2"></i> Go {this.state.modesList[1]}</DropdownReact.Item>
                                {this.state.modesList[2] === 'RESEARCH' && (this.state.userRole === 'USER' || this.state.userRole === '' || this.state.userRole === null) ? null :
                                  <DropdownReact.Item onClick={() => this.sendCommand(this.state.modesList[2])}><i className="mdi mdi-pencil-outline mr-2"></i> Go {this.state.modesList[2]} </DropdownReact.Item>
                                }
                                {this.state.modesList[3] === 'OFFLINE' ? null : this.state.modesList[3] === 'RESEARCH' && (this.state.userRole === 'USER' || this.state.userRole === '' || this.state.userRole === null) ?
                                  null : <DropdownReact.Item onClick={() => this.sendCommand(this.state.modesList[3])}><i className="mdi mdi-pencil-outline mr-2"></i> Go {this.state.modesList[3]} </DropdownReact.Item>
                                }

                              </DropdownReact.Menu>

                            </DropdownReact>
                          </div>
                        </li>        
                        <li className="list-group-item border-0 ps-3 pe-2">
                          <div className="dropdown dropdown-arrow-none">
                            <DropdownReact style={{ height: "36px", marginTop: "3px" }} size="lg">
                              <DropdownReact.Toggle size="lg" className="btn p-0 float-right text-dark bg-transparent border-0 hide-carret">
                                <i className="mdi mdi-24px mdi-eye border-0"></i>
                              </DropdownReact.Toggle>

                              <DropdownReact.Menu>                   
                                <DropdownReact.Item onClick={this.deviceMetaModalOpen}><i className="mdi mdi-eye-outline mr-2"></i> Device Profile </DropdownReact.Item>
                              </DropdownReact.Menu>

                            </DropdownReact>
                          </div>
                        </li>
                        <li className="list-group-item border-0 ps-2 pe-2 filter-toolbar">
                          <div>
                            <a onClick={this.toggleMobileFilterBar} className="a-href-highlight-remove">
                              <div className="dropdown dropdown-arrow-none">
                                <i className="mdi mdi-24px mdi-filter-menu-outline float-end"></i>
                              </div>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  <div>
                    {this.state.open ? 
                    <DeviceMeta open={this.state.open} commsVersion={this.state.commsVersion} controllerVersion={this.state.controllerVersion} metadata={this.state.deviceMetaData} userRoleData={this.state.userRole} parentCallback={this.handleResponseModal} />
                    : null}
                    
                    <SingleDeviceOtaUpdate visible={this.state.visible} selectedDeviceUdi={this.state.latestUdi} nickname={this.state.tabTitle}
                      targetFirmwareData={this.state.targetFirmwareList} deviceId={this.state.latestDeviceIdState} firmwareType={this.state.firmwareType} parentCallback={this.handleOtaUpdateModal} />
                  </div>
                </div>
              </div>
              </div>
              { this.state.openMobileFilterBar ? 
                <div className="card filter-toolbar ps-0 pe-0 mt-2">
                  <div className="card-body pe-0 ps-0 pt-0 pb-0">
                    <div className="row">

                      <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 align-items-center">
                        <ul className="list-group list-group-horizontal pe-0 d-flex align-items-center">
                          <li style={{width: "100%"}} className="list-group-item border-0">
                            <Select placeholder="Devices"
                              id="device-select" label="Devices" style={{ width: "100%" }}
                              defaultValue={this.state.latestDeviceNameState} value={this.state.nickname}
                              onChange={this.handleDeviceFilterChange}>

                              {this.state.deviceMapState.map((make, index) => (
                                <Option key={make.deviceId} value={make.deviceId}>{make.nickname}</Option>
                              ))}
                            </Select>
                          </li>
                        </ul>
                      </div>

                      <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 align-items-center">
                        <ul className="list-group list-group-horizontal pe-0 d-flex align-items-center">
                          <li style={{width: "100%"}} className="list-group-item border-0">
                              <Select placeholder="Date & Time"
                              id="datefilter-select" label="datefilter select" style={{ width: "100%" }}
                              defaultValue={this.state.typeOfDateFilter}
                              value={this.state.typeOfDateFilter}
                              onChange={this.onChangeFilterDateTime} >
                              <Option key='time' value='time'>Time</Option>
                              <Option key='datetime' value='datetime'>Date & Time</Option>
                            </Select>
                          </li>
                        </ul>
                      </div>

                      <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 align-items-center">
                        <ul className="list-group list-group-horizontal pe-0 d-flex align-items-center">
                          <li style={{width: "100%"}} className="list-group-item border-0">
                            { this.state.timeFilter === 'true' ?
                                <Select placeholder="Time"
                                  id="time-range-select" label="Time" style={{ width: "100%" }}
                                  defaultValue={2}
                                  onChange={this.handleTimeFilterChange} >
                                  <Option value={2}>Now</Option>
                                  <Option value={5}>Last 5 minutes</Option>
                                  <Option value={15}>Last 15 minutes</Option>
                                  <Option value={30}>Last 30 minutes</Option>
                                  <Option value={60}>Last 1 hour</Option>
                                  <Option value={180}>Last 3 hours</Option>
                                  <Option value={360}>Last 6 hours</Option>
                                  <Option value={720}>Last 12 hours</Option>
                                  <Option value={1440}>Last 24 hours</Option>
                                </Select>
                              :
                                <Space style={{width: "100%"}} direction="vertical" size={20}>
                                  <RangePicker
                                    showTime={{
                                      format: 'HH:mm',
                                    }}
                                    value={this.state.selectedDatetime}
                                    format="YYYY-MM-DD HH:mm"
                                    onChange={this.onChange}
                                    allowClear={false}
                                    style={{ width: "100%", height: "32px" }}
                                  />
                                </Space>
                            }
                          </li>
                        </ul>
                      </div>

                      <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 align-items-center">
                        <ul className="list-group list-group-horizontal pe-0 d-flex align-items-center">
                          <li style={{width: "100%"}} className="list-group-item border-0">
                            <Dropdown.Button onClick={this.refresh5Sec} overlay={this.menu} defaultValue="5s" placement="bottom" icon={<DownOutlined />}>
                              <div className="d-inline-flex align-items-center">
                                {this.state.selectedRefreshValue} &nbsp; <SyncOutlined />
                              </div>
                            </Dropdown.Button>
                          </li>
                        </ul>
                      </div>   
                    </div>
                  </div>
                </div>
              : null }
            </div>
          
          </div>
        </Affix>

        <div className='container-fluid mb-2 mt-2'>
          <div className="row d-flex justify-content-start">
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-5 col-xxl-5 pb-2 threshold-comp" >
            <div className='row mb-1 '>
              <ThresholdComponent clo2={this.state.clo2DonutValue} target={this.state.target} modeIcon={this.state.modeIcon} modeColor={this.state.modeColor} mode={this.state.mode} 
                humidity={this.state.humidityDonutValue} temp={this.state.temperatureDonutValue} baroP={this.state.barometricDonutValue} refreshState={this.state.thresholdRefreshState} minClO2Value={this.state.minClO2Value} maxClO2Value={this.state.maxClO2Value} />
              </div>
              <div className='row p-0 d-flex justify-content-start'>
              <LevelComponent cartridgeLevel={this.state.cartidgeLevel} />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-7 pb-2 ">
              <div className='row mb-2 '>
              <TimeseriesChartComponent tsLabel={this.state.tsLabel} tsClo2Data={this.state.tsClo2Data} tsShotSizeData={this.state.tsShotSizeData} tsRefreshStatus={this.state.timeSeriesRefreshState}  timeFilter={this.state.tsTimeFilterMins} tsHumidityData={this.state.tsHumidityData}/>
              </div>
              <div className='row '>
              <StateChartComponent stateChartSeriesData={this.state.stateChartSeries} stateChartRefreshState={this.state.stateChartRefreshState} />
                </div>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-2">
                </div>
                {this.state.timeSeriesRefreshState === true ? <SyncOutlined spin style={{ float: 'right' }} />
                      : <SyncOutlined spin style={{ float: 'right', color: 'white' }} />}   
                <DataTable title="eventLog" tableData = { this.state.datagridTelemetryMapState } refreshState={ false } />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SingleDevice;
